import useStore from "../context/useStore";

//permet de récupérer n'importe quelle constante en appelant const {constant_name} = useConstants()
const useConstants = () => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  return constants;
};

export default useConstants;
