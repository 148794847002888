import React from "react";
import useFile from "./useFile";
import "./docItem.scss";
import Loader from "../../../../common/Loader";
import { DateTime } from "luxon";
import ComingSoon from "./ComingSoon";
import KbisDropdown from "./KbisDropdown";

const DocItems = ({ files, fileType, onDeleted }) => {
  const { downloadFile, isDownloading, deleteFile, isDeleting } = useFile({ files, onDeleted });

  return !files || typeof files != "object" || files.length == 0 ? (
    <p className="mb-0 text-disabled px-2">Aucun document</p>
  ) : (
    <div className="row mx-0">
      {files.map((file, fk) => {
        const isBeingDownloaded = isDownloading == file.fileId;

        return file.isComing ? (
          <div className="mb-2">
            <ComingSoon />
          </div>
        ) : (
          <div className={`col-12 col-md-4 px-2 pb-2`} key={`filein${file.fileId}`}>
            <div className="bg-verylight text-secondary border shadow-sm rounded pt-0 px-3 pb-3">
              <div className="d-flex justify-content-start justify-content-md-between align-items-start align-items-md-start flex-column  w-100">
                <div className="d-flex flex-column w-100">
                  <div>
                    <div className="d-flex justify-content-end">
                      <span
                        className="  px-2  rounded-bottom text-secondary"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          background: "#e7efff",
                          fontSize: 11,
                          maxWidth: 180,
                        }}
                      >
                        {fileType.name}
                      </span>
                    </div>
                    {file.labels.map((label) => (
                      <div key={`flabel${file.fileId}${label.label}`}>
                        <div>
                          <strong style={{ fontSize: 16 }}>{label.label}</strong>
                        </div>
                        <div>
                          <small>{label.descriptions?.join(", ") || ""}</small>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex text-info justify-content-start mt-2">
                    <small>
                      <i className="fa fa-calendar mr-2" />
                      {DateTime.fromISO(file.uploadedAt).toFormat("dd/MM/yyyy")}
                    </small>
                  </div>
                </div>
                <div className=" w-100 d-flex justify-content-between align-items-center flex-row ">
                  <div className="py-1">
                    <button
                      className="btn btn-default d-center  w-100 px-2"
                      onClick={() => {
                        if (isBeingDownloaded) return false;
                        if (file.fileId) {
                          downloadFile(file.fileId);
                        }
                        if (file.externalUrl) {
                          window.open(file.externalUrl);
                        }
                      }}
                    >
                      {isBeingDownloaded ? (
                        <Loader size={15} color="black" className="mr-1" />
                      ) : (
                        <i className="fa fa-download mr-1" />
                      )}
                      Télécharger
                    </button>
                  </div>
                  {fileType.id == "KBIS" && <KbisDropdown fileType={fileType} files={files} />}
                  {!file.isOfficial && (
                    <div className="p-1">
                      {isDeleting == file.fileId ? (
                        <Loader />
                      ) : (
                        <button
                          className="btn btn-link  d-center w-100 px-2"
                          onClick={() => deleteFile(file.fileId, fileType)}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DocItems;
