import React, { useState } from "react";

import useStore from "../../../../../../context/useStore";
import Control from "../../../../../common/Controls/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import NewCBForm from "./NewPaymentMethodForm/NewCBForm";
import NewSEPAForm from "./NewPaymentMethodForm/NewSEPAForm";
import usePaymentMethods from "./usePaymentMethods";

const CreatePaymentMethod = ({ onFinish }) => {
  const { saveNewPaymentMethod, isCreatingPaymentMethod, paymentErrors } =
    usePaymentMethods(onFinish);
  const [state, dispatch] = useStore();
  const [methodType, setMethodType] = useState("");

  const { PAYMENT_TYPES } = state.constants.items;

  return (
    <>
      <Control
        label="Quelle méthode de paiement souhaitez vous ajouter ?"
        type="btnList"
        datas={PAYMENT_TYPES}
        dataIndex="id"
        dataLabel="name"
        btnInline
        value={methodType}
        change={(e) => setMethodType(e.target.value)}
      />

      {methodType == "CB" && <NewCBForm />}
      {methodType == "SEPA" && <NewSEPAForm />}
      {paymentErrors && <div className="alert alert-danger">{paymentErrors}</div>}
      <div className="d-flex justify-content-between mt-2">
        <button className="btn btn-link" onClick={onFinish}>
          Annuler
        </button>
        <SaveBtn
          text="Enregistrer et remplacer"
          save={() => saveNewPaymentMethod(methodType)}
          isSaving={isCreatingPaymentMethod}
        />
      </div>
    </>
  );
};

export default CreatePaymentMethod;
