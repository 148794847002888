export const MODAL_TYPES = {
  UPDATE_MODAL_DATA: "UPDATE_MODAL_DATA",
};

const initialState = {
  id: "infotext-modal",
  title: "",
  isOpen: false,
  setIsOpen: () => {},
  content: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MODAL_TYPES.UPDATE_MODAL_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
