import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../../helpers/dataService";
import {
  changeSelectedAddress,
  populateBillingAddresses,
  populateBillingAddressesAndSetSelected,
} from "../../../subscriptionContext/subscriptionReducer";
import { useSubscriptionStore } from "../../../subscriptionContext/SubscriptionStore";

const useBillingAddresses = (onFinish) => {
  const [subState, dispatch] = useSubscriptionStore();
  const [errors, setErrors] = useState({});
  const [isLoadingAddresses, setIsLoadingAddresses] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const getBillingAddresses = (noLoading = false) => {
    if (!noLoading) {
      setIsLoadingAddresses(true);
    }
    dataService.get(
      `customer-addresses/mines/BILLING`,
      (datas) => {
        setIsDeleting(false);
        dispatch(populateBillingAddresses(datas));
      },
      (err) => {},
      () => setIsLoadingAddresses(false)
    );
  };

  const saveBillingAddress = (selectedAddress, noDispatch = false) => {
    setIsSaving(true);
    dataService.patch(
      `subscriptions/${subState.id}/billing-address`,
      {
        billingAddress: selectedAddress,
      },
      (data) => {
        if (!noDispatch) {
          dispatch(
            changeSelectedAddress(subState.billingAddresses.find((b) => b.id == selectedAddress))
          );
        }
        toast.success("Adresse de facturation modifiée");
        onFinish();
      },
      (err) => {},
      () => {
        setIsSaving(false);
      }
    );
  };

  const modifyBillingAddress = (newAddress) => {
    //ajouter l'envoi de la nouvelle adresse à l'api
    setIsSaving(true);
    dataService.patch(
      `customer-addresses/${newAddress.id}`,
      newAddress,
      (datas) => {
        dispatch(changeSelectedAddress(datas));
        saveBillingAddress(newAddress.id, true);

        onFinish();
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  const createBillingAddress = (newAddress) => {
    //ajouter l'envoi de la nouvelle adresse à l'api
    setIsSaving(true);
    dataService.post(
      `customer-addresses`,
      newAddress,
      (datas) => {
        dispatch(
          populateBillingAddressesAndSetSelected({
            billingAddresses: [...subState.billingAddresses, { ...datas }],
            billingAddress: datas,
          })
        );

        saveBillingAddress(datas.id, true);
        toast.success("Nouvelle adresse créée");

        onFinish();
      },
      setErrors,
      () => setIsSaving(false)
    );
  };

  const deleteBillingAddress = (addressId) => {
    if (window.confirm("Confirmez-vous la suppression de cette adresse ?")) {
      setIsDeleting(addressId);
      dataService.remove(
        `customer-addresses/${addressId}`,
        {},
        (datas) => {
          getBillingAddresses(true);
          toast.success("Adresse supprimée avec succès");
        },
        (err) => setIsDeleting(false)
      );
    }
  };

  return {
    subState,
    isLoadingAddresses,
    isDeleting,
    setIsLoadingAddresses,
    getBillingAddresses,
    saveBillingAddress,
    modifyBillingAddress,
    createBillingAddress,
    deleteBillingAddress,
    isSaving,
    errors,
  };
};

export default useBillingAddresses;
