import React from "react";

const SubscriptionStore = React.createContext();

export const useSubscriptionStore = () => React.useContext(SubscriptionStore);

export const SubscriptionStoreProvider = ({ children, initialState, reducer }) => {
  const [subscriptionState, dispatch] = React.useReducer(reducer, initialState);

  return <SubscriptionStore.Provider value={[subscriptionState, dispatch]}>{children}</SubscriptionStore.Provider>;
};
