import React from "react";
import Loader from "./Loader";

const styles = {
  transition: "1s",
};

const SaveBtn = ({
  save,
  isSaving,
  isSaved,
  disabled = false,
  text = "Enregistrer",
  type = "success",
  className = "",
  typeBtn = "button",
  loaderColor = "#ffffff",
  style = {},
}) => {
  return (
    <button
      type={typeBtn}
      disabled={disabled || isSaving}
      className={"d-flex align-items-center btn btn-" + type + " " + className}
      onClick={save}
      style={isSaving ? { ...styles, ...style, opacity: ".7" } : { ...styles, ...style }}
    >
      <span className={isSaving || isSaved ? "mr-2" : ""}>{text}</span>
      {isSaving && <Loader color={loaderColor} size={15} />}
      {isSaved && !isSaving && <i className="fa fa-check" />}
    </button>
  );
};

export default SaveBtn;
