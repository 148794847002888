import React from "react";
import { colors } from "../../../../../helpers/colors";
import useCancelSubscription from "../useCancelSubscription";
import CircleProgress from "./CircleProgress";

const NavigationBlock = ({ blockName, blockIndex, isActive, isComplete, className = "" }) => {
  const { actions } = useCancelSubscription();

  const goToStep = () => {
    if (isComplete) actions.goToStep(blockIndex);
  };

  return (
    <div className="w-100">
      <div
        className={`d-center navigation-block flex-column px-2 ${
          isActive
            ? "text-secondary font-weight-bold active"
            : isComplete
            ? "text-secondary cursor-pointer complete"
            : "text-grey"
        } ${className}`}
        onClick={goToStep}
      >
        <CircleProgress isComplete={isComplete} />
        <span
          className="py-3 navigation-block-text"
          style={{ color: `${isComplete || isActive ? colors.secondary : "grey"}` }}
        >
          {blockName}
        </span>
      </div>
    </div>
  );
};

export default NavigationBlock;
