import React from "react";
import useStore from "../../../../context/useStore";
import Colors from "../../../../theme/variables.scss";

const NotificationBtn = ({ setDropdownVisible }) => {
  const [state, dispatch] = useStore();
  const notifications = state.notifications.items;

  const nbUnreadNotifications = notifications.filter((n) => !n.readAt).length;

  return (
    <button
      className="btn text-secondary position-relative avatar-dropdown justify-content-end mb-0"
      onClick={() => {
        setDropdownVisible((v) => !v);
      }}
    >
      <i className="far  fa-bell mt-1" style={{ fontSize: 22 }} />
      {nbUnreadNotifications > 0 ? (
        <strong
          className="d-center text-white bg-primary"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 20,
            height: 20,
            background: Colors.primary,
            borderRadius: "50%",
            fontSize: 12,
          }}
        >
          {nbUnreadNotifications}
        </strong>
      ) : null}
    </button>
  );
};

export default NotificationBtn;
