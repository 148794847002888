import React, { useEffect, useState } from "react";
import Loader from "../../../../../common/Loader";
import CreatePaymentMethod from "./CreatePaymentMethod";
import SelectPaymentMethod from "./NewPaymentMethodForm/SelectPaymentMethod";
import PaymentMethodButton from "./PaymentMethodButton";
import usePaymentMethods from "./usePaymentMethods";

const PaymentMethods = ({ disabled }) => {
  const { getPaymentMethods, subState, isLoadingPaymentMethods } = usePaymentMethods();
  const [mode, setMode] = useState(false);

  useEffect(() => {
    getPaymentMethods();
  }, []);

  console.log("subState", subState);

  const selectedPaymentMethod = subState.paymentMethods?.find(
    (p) => p.paymentMethodId == subState.providerData.paymentMethodId
  );

  const onFinishCreatePM = () => {
    setMode(false);
  };
  const onFinishSelectPM = () => {
    setMode(false);
  };

  return isLoadingPaymentMethods ? (
    <Loader />
  ) : (
    <>
      <strong
        className="d-flex justify-content-between align-items-center mb-2"
        style={{ fontSize: 22 }}
      >
        Méthode de paiement
        <small className="bg-default rounded px-2 text-secondary d-inline-center">
          {subState.paymentMethods?.length || ""}
        </small>
      </strong>
      {!mode ? (
        <div>
          {selectedPaymentMethod && (
            <PaymentMethodButton
              datas={[selectedPaymentMethod]}
              name="selectedPaymentMethod"
              value={selectedPaymentMethod.paymentMethodId}
              dataIndex="paymentMethodId"
            />
          )}
          {!disabled && (
            <div className="d-flex flex-column">
              {subState.paymentMethods?.length > 1 && (
                <button
                  className="btn btn-link text-secondary mt-2 d-inline-flex align-items-center"
                  onClick={() => setMode("select")}
                >
                  <i className="fa fa-list mr-2" />
                  Choisir une autre méthode de paiement
                </button>
              )}
              <button
                className="btn btn-link text-secondary  mt-2 d-inline-flex align-items-center"
                onClick={() => setMode("create")}
              >
                <i className="fa fa-plus mr-2" />
                Ajouter une nouvelle méthode de paiement
              </button>
            </div>
          )}
        </div>
      ) : mode == "create" ? (
        <CreatePaymentMethod onFinish={onFinishCreatePM} />
      ) : mode == "select" ? (
        <SelectPaymentMethod onFinish={onFinishSelectPM} />
      ) : null}
    </>
  );
};

export default PaymentMethods;
