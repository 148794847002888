import React from "react";
import { Link } from "react-router-dom";
import { authActions } from "../../../../context/actions/authActions";
import useStore from "../../../../context/useStore";

const Account = ({ setDropdownVisible, avatarVisible, authData }) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);

  return (
    <div className="btn-group top-dropdown ">
      <button
        type="button"
        className="border-0 avatar-dropdown mb-0"
        onClick={() => {
          setDropdownVisible((v) => !v);
        }}
      >
        <i className="far fa-user text-secondary" style={{ fontSize: 22 }} />
      </button>
      <div className="top-dropdown-menu rounded shadow-lg p-0">
        {/* <div className="py-2 text-center  text-capitalize font-weight-bold bg-secondary text-white">
          {state.auth.user.firstname} {state.auth.user.lastname}
        </div> */}
        <Link
          onClick={() => setDropdownVisible(false)}
          className="dropdown-item px-3"
          to="/account"
        >
          <i className="fa fa-palette mr-3" />
          Mon compte
        </Link>
        {/* <Link onClick={() => setDropdownVisible(false)} className="dropdown-item" to="/orders">
          <i className="fa fa-list-alt mr-3" />
          Commandes
        </Link> */}
        {/* <Link className="dropdown-item" to="#">
            <i className="fa fa-cubes mr-3" />
            Gestion du coffre
          </Link> */}
        <button className="dropdown-item px-3" onClick={actions.logoutUser}>
          <i className="fa fa-power-off mr-3" />
          Déconnexion
        </button>
      </div>
    </div>
  );
};

export default Account;
