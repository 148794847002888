import React, { useState } from "react";
import Colors from "../../../theme/variables.scss";
import { DateTime } from "luxon";
import "./DocumentCard.scss";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";
import Loader from "../Loader";
import { API_URL } from "../../../config";
import Axios from "axios";
import useStore from "../../../context/useStore";
import DropFile from "../DropFile";
import dataService from "../../../helpers/dataService";
import { Link } from "react-router-dom";
import { modalActions } from "../../../context/actions/modalActions";
import ShareDocument from "../ShareDocument/ShareDocument";

const DocumentCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const notifications = state.notifications.items;
  var statuses = state.constants?.items?.PAYMENT_STATUS;
  var paymentTypes = state.constants?.items?.PAYMENT_TYPES;
  var documentTypes = state.constants?.items?.DOCUMENT_TYPES;
  var personalDocumentTypes = state.constants?.items?.PERSONAL_DOCUMENT_TYPES;
  //var canUpload = !props.isCompany;
  var canUpload = props.noUpload ? false : true;
  const [showActions, setShowActions] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  var isUploaded = props.uploadedAt ? true : false;
  var isBuyable = documentTypes.find((d) => d.id == props.type)
    ? documentTypes.find((d) => d.id == props.type).isBuyable
    : false;

  const download = () => {
    if (isLoading) return false;
    setIsLoading(true);
    Axios({
      url: API_URL + "files/" + props.id,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        fileDownload(res.data, props.fileName);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const remove = (e) => {
    if (e) e.stopPropagation();
    if (!window.confirm("Confirmez-vous la suppression de ce document ?"))
      return false;
    setIsDeleting(true);
    dataService.remove(
      `chests/${props.chest}/files/${props.id}`,
      {},
      (datas) => {
        setIsDeleted(true);
        props.reload();
      },
      (err) => {
        if (err.response && err.response.data) {
          Object.values(err.response.data).forEach((e) => toast.error(e));
        }
      },
      () => setIsDeleting(false)
    );
  };

  const upload = (e) => {
    e.stopPropagation();
    if (isUploaded) {
      if (
        window.confirm(
          "Un document existe déjà dans cet emplacement, si vous souhaitez tout de même le renvoyer, cliquez sur ok. Le document actuel sera supprimé."
        )
      ) {
        remove();
      }
    } else {
      document.querySelector(`#doc-${props.chestId}${props.type}`).click();
    }
  };

  const share = (e) => {
    e.stopPropagation();
    modal_actions.updateModal({
      isOpen: true,
      title: "Partager le document",
      content: <ShareDocument doc={props} />,
      actions: null,
    });
  };

  const isExpired =
    isUploaded &&
    notifications.find(
      (n) => n.type == "DOCUMENT_EXPIRED" && n.entityParams.fileId == props.id
    );

  return (
    <>
      {!statuses || statuses.length == 0 || isDeleting ? null : (
        <div
          className={
            " flex-column justify-content-start align-items-start  document-card animated fadeInRight  " +
            (isUploaded ? " active " : " ") +
            (props.className ? props.className : " mr-5 ")
          }
          style={props.style || {}}
          onClick={
            isUploaded
              ? download
              : () => {
                  if (!canUpload) {
                    toast.info(
                      "Ce document sera bientôt disponible dans votre espace une fois qu'il aura été récupéré par nos services."
                    );
                  }
                }
          }
        >
          <div
            className="bottom-part w-100 text-center py-1 px-2 d-center mb-1 border font-weight-bold"
            style={{ height: 50 }}
          >
            {props.isCompany
              ? documentTypes.find((s) => s.id == props.type)?.name
              : personalDocumentTypes.find((s) => s.id == props.type)?.name}

            {isExpired && (
              <strong
                className="d-center text-white"
                style={{
                  position: "absolute",
                  top: -3,
                  left: -6,
                  width: 20,
                  height: 20,
                  background: Colors.primary,
                  borderRadius: "50%",
                  fontSize: 12,
                }}
              >
                <i title="Ce document a expiré" className="fa fa-exclamation" />
              </strong>
            )}
          </div>

          <div
            className={`top-part mb-2 w-100 d-flex flex-column align-items-center justify-content-center position-relative overflow-hidden border ${
              canUpload ? "" : "px-3"
            }`}
          >
            {isUploaded ? (
              <>
                {isLoading ? (
                  <Loader color={"#e2e8ed"} />
                ) : (
                  <>
                    <i
                      className={
                        "fa fa-" +
                        (props.ext == "pdf"
                          ? "file-pdf"
                          : props.ext
                          ? "file-image"
                          : "file-download")
                      }
                    />
                    <div
                      className="w-100 text-center py-1 px-2 mt-2 upload-date"
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {isUploaded
                        ? "Envoyé le " +
                          DateTime.fromISO(props.uploadedAt).toFormat(
                            "dd / MM / yyyy"
                          )
                        : props.mustBeUploadedAt
                        ? "A partir du " +
                          DateTime.fromISO(props.mustBeUploadedAt).toFormat(
                            "dd / MM / yyyy"
                          )
                        : ""}
                    </div>
                  </>
                )}
                <span>{props.ext}</span>
              </>
            ) : canUpload ? (
              <DropFile
                type={props.type}
                chestId={props.chestId}
                setDocuments={props.setDocuments}
              />
            ) : (
              <div style={{ fontSize: 15 }} className="text-center">
                EN ATTENTE D'ENVOI
                <br />{" "}
                {props.mustBeUploadedAt &&
                  "à partir du " +
                    DateTime.fromISO(props.mustBeUploadedAt).toFormat(
                      "dd / MM / yyyy"
                    )}
              </div>
            )}
          </div>

          <div className="btn-group d-center flex-row w-100" role="group">
            {isBuyable && canUpload ? (
              <Link
                to={`/command/${isExpired ? "renew" : ""}`}
                className="btn btn-primary btn-sm d-flex align-items-center justify-content-start"
              >
                <i className="fa fa-cart-plus mr-2" />
                {isExpired ? "Renouveler" : "Commander"}
              </Link>
            ) : canUpload ? (
              <button
                className="btn btn-secondary btn-sm d-flex align-items-center justify-content-start"
                onClick={upload}
              >
                <i className="fa fa-file-upload mr-2" />
                Envoyer
              </button>
            ) : null}
            {canUpload && (
              <button
                type="button"
                className="btn btn-default btn-sm d-center"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowActions(!showActions);
                }}
                style={{ height: 28, maxWidth: 30 }}
              >
                <i
                  className="fa fa-ellipsis-v mx-auto"
                  style={{ fontSize: 18 }}
                />
              </button>
            )}
          </div>
          {showActions && (
            <div>
              {isBuyable && !isUploaded && canUpload && (
                <button
                  className="btn btn-secondary btn-sm w-100 text-left mt-1"
                  onClick={upload}
                >
                  <i className="fa fa-file-upload mr-2" />
                  Envoyer
                </button>
              )}
              {isUploaded && (
                <>
                  <button
                    className="btn btn-success btn-sm w-100 text-left mt-1"
                    onClick={download}
                  >
                    <i className="fa fa-file-download mr-2" />
                    Télécharger
                  </button>
                  <button
                    onClick={share}
                    className="btn btn-tertiary btn-sm w-100 text-left mt-1"
                  >
                    <i className="fa fa-link mr-2" />
                    Partager
                  </button>
                </>
              )}
              {isUploaded && props.reload && !isBuyable && (
                <button
                  className="btn btn-danger btn-sm w-100 text-left mt-1"
                  onClick={remove}
                >
                  <i className="fa fa-times mr-2" />
                  Supprimer
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default DocumentCard;
