import axios from "axios";
import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import { API_URL } from "../config";
import { authActions } from "../context/actions/authActions";
import { constantActions } from "../context/actions/constantActions";
import { notificationActions } from "../context/actions/notificationActions";
import { AUTH_TYPES } from "../context/reducers/authReducers";
import useStore from "../context/useStore";
var firstLoad = true;

const useAuth = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  var c_actions = constantActions(state, dispatch);
  var n_actions = notificationActions(state, dispatch);
  const [isRefreshingAuth, setIsRefreshingAuth] = useState(false);

  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      if (error.response.status == 401) {
        console.log("UNAUTHORIZED - login out", error.response.config.url);
        if (error.response.config.url !== API_URL + "auth/logout") {
          actions.logoutUser();
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );

  if (localStorage.jwtToken && firstLoad) {
    firstLoad = false;
    // Set auth token header auth
    //tools.setAuthToken(localStorage.jwtToken);
    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    dispatch({
      type: AUTH_TYPES.SET_CURRENT_USER,
      payload: decoded,
    });

    // Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user

      actions.logoutUser();
      // Clear current Profile
      // store.dispatch(clearCurrentProfile())
      // Redirect to login
    }
  }

  const refreshAuth = () => {
    setIsRefreshingAuth(true);
    axios
      .get(API_URL + "auth/me")
      .then((res) => {
        const decoded = jwt_decode(res.data);
        setIsRefreshingAuth(false);

        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: decoded,
        });
      })
      .catch((err) => {
        actions.logoutUser();
      });
  };

  useEffect(() => {
    if (state.auth.isAuthenticated) {
      n_actions.getNotifications();
    }
  }, [state.auth.isAuthenticated]);

  useEffect(() => {
    if (
      !localStorage.jwtToken ||
      (localStorage.jwtToken && !state.auth.isAuthenticated)
    ) {
      refreshAuth();
    }
    c_actions.getConstants();
  }, []);

  return { refreshAuth, isRefreshingAuth };
};

export default useAuth;
