import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import SaveBtn from "../../../../../common/SaveBtn";
import useBillingAddresses from "./useBillingAddresses";

const CreateBillingAddress = ({ onFinish }) => {
  const { subState, isSaving, createBillingAddress, getBillingAddresses, errors } = useBillingAddresses(onFinish);
  const [newAddress, setNewAddress] = useState({
    name: "",
    address1: "",
    address2: "",
    postalCode: "",
    city: "",
  });

  const handleChangeAddress = (e) => {
    const { name, value } = e.target;
    setNewAddress({ ...newAddress, [name]: value });
  };

  return (
    <div>
      <div>
        <strong>Ajouter une nouvelle adresse</strong>
      </div>

      <Control
        label="Nom"
        type="text"
        name="name"
        value={newAddress.name}
        placeholder="Maison, bureau, ..."
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Adresse"
        type="text"
        name="address1"
        value={newAddress.address1}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Adresse complément"
        type="text"
        name="address2"
        value={newAddress.address2}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Code postal"
        type="text"
        name="postalCode"
        value={newAddress.postalCode}
        change={handleChangeAddress}
        error={errors}
      />

      <Control
        label="Ville"
        type="text"
        name="city"
        value={newAddress.city}
        change={handleChangeAddress}
        error={errors}
      />

      <div className="d-flex justify-content-between">
        <button className="btn btn-link" onClick={onFinish}>
          Annuler
        </button>
        <SaveBtn
          text="Enregistrer et remplacer"
          className="mt-2"
          save={() => createBillingAddress(newAddress)}
          isSaving={isSaving}
        />
      </div>
    </div>
  );
};

export default CreateBillingAddress;
