import { DateTime } from "luxon";
import React from "react";
import Loader from "../../../../common/Loader";
import ComingSoon from "./ComingSoon";
import KbisDropdown from "./KbisDropdown";
import useFile from "./useFile";

const DocLines = ({ files, fileType, onDeleted }) => {
  const { downloadFile, isDownloading, deleteFile, isDeleting } = useFile({ files, onDeleted });

  return !files || typeof files != "object" || files.length == 0 ? (
    <tr>
      <td colSpan="4" className="text-disabled">
        Aucun document
      </td>
    </tr>
  ) : (
    files.map((file) => {
      const isBeingDownloaded = isDownloading == file.fileId;
      return file.isComing ? (
        <tr key={`file${fileType.name}${file.fileId}`}>
          <td colSpan="4" className="text-success">
            <ComingSoon />
          </td>
        </tr>
      ) : (
        <tr className="table-tr" key={`file${fileType.name}${file.fileId}`}>
          <td className="align-middle">
            {file.labels.map((label) => (
              <div key={`flabel${file.fileId}${label.label}`}>
                <div>
                  <strong>{label.label}</strong>
                </div>

                <div>
                  <small className="text-info">{label.descriptions?.join(", ") || ""}</small>
                </div>
              </div>
            ))}
            {fileType.id == "KBIS" && <KbisDropdown fileType={fileType} files={files} />}
          </td>
          <td className="align-middle">
            <span
              className="  px-2  rounded text-secondary"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                background: "#e7efff",
                fontSize: 11,
                maxWidth: 140,
              }}
              title={fileType.name}
            >
              {fileType.name}
            </span>
          </td>
          <td className="align-middle">
            {DateTime.fromISO(file.uploadedAt).toFormat("dd/MM/yyyy")}
          </td>
          <td className="align-middle">
            <div className=" w-100 d-flex justify-content-between align-items-center flex-row ">
              <div className="p-1">
                <button
                  className="btn btn-secondary d-center w-100 px-2 flex-column"
                  onClick={() => {
                    if (isBeingDownloaded) {
                      return false;
                    }
                    if (file.fileId) {
                      downloadFile(file.fileId);
                    }
                    if (file.externalUrl) {
                      window.open(file.externalUrl);
                    }
                  }}
                >
                  {isBeingDownloaded ? (
                    <Loader color="white" size={36} />
                  ) : (
                    <i className="fa fa-download fa-2x mb-2" />
                  )}
                  Télécharger
                </button>
              </div>

              {!file.isOfficial && (
                <div className="p-1">
                  {isDeleting == file.fileId ? (
                    <Loader />
                  ) : (
                    <button
                      className="btn btn-link  d-center w-100 px-2"
                      onClick={() => deleteFile(file.fileId, fileType)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  )}
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    })
  );
};

export default DocLines;
