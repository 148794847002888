import React from "react";
import { colors } from "../../../../../helpers/colors";

const CircleProgress = ({ isComplete, size = 40, color = "secondary", bgTransparent = false }) => {
  const progressText = isComplete ? "✔" : "";

  return (
    <div
      style={{
        width: size,
        height: size,
        background: bgTransparent ? "white" : "white",
        zIndex: "9",
        borderRadius: "50%",
        border: `2px solid ${isComplete ? colors[color] : "#d6d6d6"}`,
        backgroundColor: `${isComplete ? colors[color] : "white"}`,
        transition: "400ms",
      }}
    >
      <span
        style={{
          color: `${isComplete ? "white" : "grey"}`,
          top: 4,
          position: "relative",
          fontSize: 20,
        }}
      >
        {progressText}
      </span>
    </div>
  );
};

export default CircleProgress;
