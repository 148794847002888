import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { APP_URL } from "../../../../../../config";
import { colors } from "../../../../../../helpers/colors";
import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";
import { useChestStore } from "../../../chestContext/ChestStore";
import ShareDocument from "../ShareDocument/ShareDocument";

const ShareLinksList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const inpRef = useRef(null);
  const [links, setLinks] = useState([]);
  const [chestState, dispatch] = useChestStore();

  useEffect(() => {
    getSharedLinks();
    document.querySelector(".link-share-container").scrollIntoView();
  }, []);

  const copyLink = (link) => {
    tools.copyToClipboard(link);
    toast.info("Copié dans le presse-papier");
  };

  const getSharedLinks = () => {
    setIsLoading(true);
    dataService.get(
      `chests/${chestState.chest.id}/links`,
      setLinks,
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const deleteLink = (link) => {
    setIsDeleting(link.id);
    dataService.remove(
      `links/${link.id}`,
      {},
      (data) => {
        toast.success("Ce lien a bien été supprimé");
        setLinks(links.filter((l) => l.id != data.id));
      },
      (err) => {},
      () => setIsDeleting(false)
    );
  };

  return (
    <div className="mt-4 px-3  w-100">
      <div
        style={{ fontSize: 22 }}
        className="text-secondary d-flex justify-content-md-between justify-content-start flex-md-row flex-column link-share-container"
      >
        <strong className="mr-2" style={{ fontSize: 24, fontWeight: "550" }}>
          Liens de partage créés pour{" "}
          <span className="text-black-50">{chestState.chest.companyName}</span>
        </strong>
        <ShareDocument onCreated={getSharedLinks} />
      </div>
      {isLoading ? (
        <div className="d-center w-100">
          <Loader />
        </div>
      ) : (
        <div className="d-flex flex-wrap align-items-start justify-content-start">
          {links.length == 0 ? (
            <div className="text-disabled mt-2">Aucun lien de partage.</div>
          ) : (
            links.map((link) => (
              <div
                key={`link${link.id}`}
                className="p-3 rounded shadow-sm bg-white border mr-2 my-2 position-relative"
              >
                <details>
                  <summary className="font-weight-bold text-secondary" style={{ fontSize: 18 }}>
                    {link.files.length} document{link.files.length > 1 ? "s" : ""}
                  </summary>
                  <ul className="mb-0">
                    {link.files.map((file, fk) => (
                      <li key={`lkfile${link.id}${fk}`}>
                        {file.labels.map((label) => label.label).join(", ")}
                      </li>
                    ))}
                  </ul>
                </details>

                <button
                  className="btn btn-default my-2"
                  onClick={() => copyLink(`${APP_URL}shared/${link.token}`)}
                >
                  <i className="fa fa-copy mr-2" />
                  Copier le lien
                </button>

                <div>
                  Téléchargé{" "}
                  <strong className="text-secondary">{link.downloadHistorics.length} fois</strong>
                </div>
                <div>
                  Expire le{" "}
                  <strong className="text-secondary">
                    {DateTime.fromISO(link.expiredAt).toFormat("dd/MM/yyyy à hh:mm")}
                  </strong>
                </div>
                <div>
                  Partagé le{" "}
                  <strong className="text-secondary">
                    {DateTime.fromISO(link.createdAt).toFormat("dd/MM/yyyy")}
                  </strong>
                </div>
                <div className="d-center w-100">
                  <button
                    disabled={isDeleting == link.id}
                    className="btn btn-link"
                    onClick={() => deleteLink(link)}
                  >
                    {isDeleting == link.id ? (
                      <Loader color={colors.primary} />
                    ) : (
                      "Supprimer ce lien"
                    )}
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default ShareLinksList;
