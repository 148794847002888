import React from "react";
import { Link } from "react-router-dom";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import { updateStatus } from "../../subscriptionContext/subscriptionReducer";
import { useSubscriptionStore } from "../../subscriptionContext/SubscriptionStore";
import CancelSubscriptionModal from "./CancelSubscriptionModal";

const SubscriptionDropDown = ({ totalAmount }) => {
  const [gState, gDispatch] = useStore();
  const mActions = modalActions(gState, gDispatch);
  const [subState, dispatch] = useSubscriptionStore();

  const openModal = () => {
    mActions.updateModal({
      isOpen: true,
      content: <CancelSubscriptionModal subscriptionId={subState.id} onCancelled={onCancelled} />,
      style: {
        width: 400,
      },
    });
  };

  const onCancelled = (data) => {
    dispatch(updateStatus(data.status));
  };

  return (
    <Link
      to={`subscriptions/${subState.id}/cancel/${totalAmount}/${subState.createdAt}/${subState.echeance}`}
      className="  btn btn-link text-secondary d-center d-inline-flex pl-0"
    >
      RESILIER
    </Link>
  );
};

export default SubscriptionDropDown;
