import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import Avatar from "../Avatar";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import $ from "jquery";
import Notifications from "./Notifications/Notifications";
import Account from "./Account/Account";

const TopHeader = () => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [avatarVisible, setAvatarVisible] = useState(true);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notifVisible, setNotifVisible] = useState(false);

  useEffect(() => {
    if (state.auth.user.newImage) {
      setAvatarVisible(false);
      setTimeout(() => {
        setAvatarVisible(true);
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  useEffect(() => {
    if (dropdownVisible) {
      $(".top-dropdown").addClass("show");
      setNotifVisible(false);
    } else {
      $(".top-dropdown").removeClass("show");
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (notifVisible) {
      $(".notif-dropdown").addClass("show");
      setDropdownVisible(false);
    } else {
      $(".notif-dropdown").removeClass("show");
    }
  }, [notifVisible]);

  useEffect(() => {
    if (!state.notifications.items.length) {
      setNotifVisible(false);
    }
  }, [state.notifications.items]);

  var authData = state.auth;

  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <div className="top-header d-flex align-items-center justify-content-end pr-5 noprint">
      <Notifications setNotifVisible={setNotifVisible} />
      <Account
        setDropdownVisible={setDropdownVisible}
        avatarVisible={avatarVisible}
        authData={authData}
      />
    </div>
  ) : (
    <></>
  );
};

export default TopHeader;
