import React from "react";

const Feature = ({ children }) => {
  return (
    <div className="d-flex align-items-center justify-content-start my-3">
      <div
        className="bg-success p-2 rounded-circle d-center mr-2"
        style={{
          width: 23,
          height: 23,
        }}
      >
        <i className="fa fa-check text-white" />
      </div>
      <span>{children}</span>
    </div>
  );
};

export default Feature;
