import React, { useState, useEffect } from "react";
import "./Table.scss";

const Table = ({ headers, datas, fields }) => {
  const [datasFiltered, setDataFiltered] = useState([]);

  useEffect(() => {
    setDataFiltered(datas);
  }, [datas]);

  return (
    <table className="table table-striped table-borderless shadow-sm custom-table">
      <thead>
        <tr>
          {fields.map((h, hk) => (
            <th className="pb-0 pt-3" key={`thead${h.name}`}>
              {h.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {datas.map((d, dk) => (
          <tr
            key={"dk" + dk}
            className="animated fadeInUp faster"
            style={{
              animationDelay: dk * 30 + "ms",
            }}
          >
            {fields.map((f, fk) => (
              <td style={{ verticalAlign: "middle" }} key={"df" + dk + "" + fk}>
                {f.render ? f.render(f.path == "*" ? d : d[f.path], dk) : d[f.path]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
