import React from "react";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import { useChestStore } from "../../../chestContext/ChestStore";
import ShareModal from "./ShareModal";

const ShareDocument = ({ onCreated }) => {
  const [state, gDispatch] = useStore();
  const [chestState, dispatch] = useChestStore();
  const mActions = modalActions(state, gDispatch);

  const openShareModal = () => {
    mActions.updateModal({
      isOpen: true,
      content: <ShareModal onCreated={onCreated} chestState={chestState} />,
    });
  };

  return (
    <button className="btn btn-secondary btn-sm" onClick={openShareModal}>
      <i className="fa fa-plus mr-2" />
      Créer un nouveau lien de partage
    </button>
  );
};

export default ShareDocument;
