import React, { useEffect, useState } from "react";
import Control from "../../../../../common/Controls/Control";
import Loader from "../../../../../common/Loader";
import SaveBtn from "../../../../../common/SaveBtn";
import useBillingAddresses from "./useBillingAddresses";

const SelectBillingAddress = ({ onFinish }) => {
  const { isLoadingAddresses, saveBillingAddress, getBillingAddresses, isSaving, subState } =
    useBillingAddresses(onFinish);

  const [selectedAddress, setSelectedAddress] = useState(subState.billingAddress.id);

  return (
    <>
      {isLoadingAddresses ? (
        <Loader />
      ) : (
        <div>
          <Control
            label="Choisissez une adresse"
            type="btnList"
            name="selectedAddress"
            value={selectedAddress}
            datas={
              subState.billingAddresses?.map((b) => ({
                ...b,
                addressFull: `${b.address1} ${b.address2} ${b.postalCode} ${b.city}`,
              })) || []
            }
            change={(e) => setSelectedAddress(e.target.value)}
            dataIndex="id"
            dataLabel="name"
            dataLabel2="addressFull"
          />
          <div className="d-flex justify-content-between">
            <button className="btn btn-link" onClick={onFinish}>
              Annuler
            </button>
            <SaveBtn className="mt-2" save={() => saveBillingAddress(selectedAddress)} isSaving={isSaving} />
          </div>
        </div>
      )}
    </>
  );
};

export default SelectBillingAddress;
