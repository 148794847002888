import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { notificationActions } from "../../../../context/actions/notificationActions";
import useStore from "../../../../context/useStore";
import { colors } from "../../../../helpers/colors";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import "./Notification.css";

const Notification = ({ notification, setDropdownVisible }) => {
  const [state, dispatch] = useStore();
  const n_actions = notificationActions(state, dispatch);

  const updateNotificationIsRead = () => {
    dataService.patch(
      `notifications/${notification.id}`,
      {
        isRead: true,
      },
      (datas) => {},
      (err) => {},
      () => n_actions.getNotifications()
    );
  };

  const isRead = notification.readAt;

  const getMessage = () => {
    switch (notification.type) {
      case "EXPIRING_CB":
        return (
          <>
            <Link
              to={`/subscriptions/${notification.actionToDo.entity}`}
              style={{
                textDecoration: "none",
              }}
              className={`${isRead ? "text-info" : ""}`}
            >
              <div
                className={isRead ? "" : `text-secondary`}
                style={{
                  fontSize: 17,
                }}
              >
                Votre carte bleu arrive à expiration.
              </div>
              aller aux abonnements
              <i className="fa fa-arrow-right ml-2" />
            </Link>
          </>
        );
      case "NEW_DOCUMENT_AVAILABLE":
        return (
          <>
            <Link
              to={`/chests/${notification.actionToDo.entity}`}
              style={{
                textDecoration: "none",
              }}
              className={`${isRead ? "text-info" : ""}`}
            >
              <div
                className={isRead ? "" : `text-secondary`}
                style={{
                  fontSize: 16,
                }}
              >
                De nouveaux documents sont disponibles dans votre coffre.
              </div>
              aller aux coffres
              <i className="fa fa-arrow-right ml-2" />
            </Link>
          </>
        );
      case "WELCOME":
        return (
          <>
            <div className={`${isRead ? "text-info" : ""}`}>
              <div
                className={isRead ? "" : `text-secondary`}
                style={{
                  fontSize: 16,
                }}
              >
                Bienvenue sur LegaDrive {tools.getFullname(state.auth.user)} 👋 !
              </div>
            </div>
          </>
        );
    }
  };

  //romaingrau+test200122@gmail.com

  return (
    <div
      className="p-3 text-secondary position-relative cursor-pointer border-bottom notification-item"
      onClick={() => {
        setDropdownVisible(false);
        updateNotificationIsRead();
      }}
      style={{
        borderLeft: isRead ? `3px solid ${colors.default}` : `3px solid ${colors.secondary}`,
      }}
    >
      <div
        className="mt-1 d-flex align-items-center justify-content-start"
        style={{
          fontSize: 13,
        }}
      >
        {/* <i
          className={`mr-3 ${
            isRead ? "fa fa-check text-default" : "fa fa-exclamation-circle text-secondary"
          }`}
        /> */}
        {getMessage()}
      </div>
      <div
        className="text-info"
        style={{
          position: "absolute",
          fontSize: 12,
          right: 5,
          bottom: 5,
        }}
      >
        {DateTime.fromISO(notification.createdAt).toFormat("dd/MM/yyyy")}
      </div>
    </div>
  );
};

export default Notification;
