export const colors = {
  primary: "#dc142d",
  secondary: "#0e2b67",
  tertiary: "#104a96",
  success: "#0f996d",
  info: "#818686",
  warning: "#ffcc99",
  dark: "#222222",
  danger: "#dc5f45",
  light: "#e2e8ed",
  default: "#e2e8ed",
  grey: "rgba(255,255,255,0.01)",
};
