import { DateTime } from "luxon";
import React from "react";
import { useChestStore } from "../../pages/Chests/chestContext/ChestStore";
import Loader from "../Loader";

const InfosComplete = (props) => {
  const [chestState, dispatch] = useChestStore();

  return !chestState.chest ? (
    <Loader />
  ) : (
    <div className="row pl-2" {...props}>
      <div className="col-12 col-md-2 col-lg-2">
        {chestState.chest.siren && (
          <div className="row py-1">
            <div className="col-12 font-weight-bold text-secondary">SIREN :</div>
            <div className="col-12">{chestState.chest.siren}</div>
          </div>
        )}
        <div className="row py-1">
          <div className="col-12  font-weight-bold text-secondary">Adresse :</div>
          <div className="col-12">
            {chestState.establishments.map(
              (est) =>
                (est.address1 || "") +
                " " +
                (est.address2 || "") +
                " " +
                (est.address3 || "") +
                ", " +
                est.postalCode +
                " " +
                est.city
            )}
          </div>
        </div>

        {chestState.chest.startActivityAt && (
          <div className="row py-1">
            <div className="col-12 font-weight-bold text-secondary">Création:</div>
            <div className="col-12">
              {DateTime.fromISO(chestState.chest.startActivityAt).toFormat("dd/MM/yyyy")}
            </div>
          </div>
        )}
      </div>

      <div className="col-12 col-md-6 col-lg-6">
        {chestState.chest.legalForm && (
          <div className="row py-1">
            <div className="col-12  font-weight-bold text-secondary">Forme juridique :</div>
            <div className="col-12">{chestState.chest.legalForm}</div>
          </div>
        )}
        {chestState.chest.activity && (
          <div className="row py-1">
            <div className="col-12  font-weight-bold text-secondary">Activité :</div>
            <div className="col-12">{chestState.chest.activity}</div>
          </div>
        )}

        {chestState.chest.rcs && (
          <div className="row py-1">
            <div className="col-12 font-weight-bold text-secondary">R.C.S :</div>
            <div className="col-12">{chestState.chest.rcs}</div>
          </div>
        )}
      </div>

      <div className="col-12 col-md-4 col-lg-4">
        {chestState.owners && (
          <div className="row py-1">
            <div className="col-12 font-weight-bold text-secondary">Direction :</div>
            <div className="col-12">
              {chestState.owners.map((ow) => (
                <div key={`owner${ow.firstname}`}>
                  <div className="badge bg-verylight">{ow.quality}</div>
                  <div>
                    {ow.firstname} {ow.lastname}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="row py-1">
          <div className="col-12 font-weight-bold text-secondary">Capital :</div>
          <div className="col-12">{chestState.chest.shareCapital}</div>
        </div>
      </div>
    </div>
  );
};

export default InfosComplete;
