import React from "react";
import useStore from "../../../../../context/useStore";
import { useChestStore } from "../../chestContext/ChestStore";
import DocItems from "./DocItems";
import tools from "../../../../../helpers/tools";
import KbisDropdown from "./KbisDropdown";

const DocumentCardView = ({ onDeleted }) => {
  const [state] = useStore();
  const { FILE_TYPES } = state.constants.items;
  const [chestState, dispatch] = useChestStore();

  const files = chestState.files || {};

  return (
    <div className="row">
      <div className="col-12">
        {Object.keys(files).map((filekey) => {
          var myfiles = files[filekey];
          var fileType = tools.findIn(FILE_TYPES, filekey, "id");
          var isKbis = fileType.id == "KBIS";
          return (
            <div className="">
              <DocItems files={myfiles} fileType={fileType} onDeleted={onDeleted} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentCardView;
