import { MODAL_TYPES } from "../reducers/modalReducers";

export const modalActions = (state, dispatch, props) => {
  function updateModal(modalData) {
    dispatch({ type: MODAL_TYPES.UPDATE_MODAL_DATA, payload: modalData });
  }

  return {
    updateModal,
  };
};
