import { useAtom } from "jotai";
import {
  chestAtom,
  chestsAtom,
  errorsAtom,
  isLoadingAtom,
  openedChestId,
} from "../components/pages/Chests/atoms/atoms";
import { subscriptionsAtom } from "../components/pages/Subscriptions/atoms/atoms";

const useAtoms = () => {
  const [chests, setChests] = useAtom(chestsAtom);
  const [chest, setChest] = useAtom(chestAtom);
  const [errors, setErrors] = useAtom(errorsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [openedChest, setOpenedChest] = useAtom(openedChestId);
  const [subscriptions, setSubscriptions] = useAtom(subscriptionsAtom);

  const resetAtoms = () => {
    setChests([]);
    setChest({});
    setErrors({});
    setIsLoading(false);
    setOpenedChest(null);
    setSubscriptions([]);
  };

  return { resetAtoms };
};

export default useAtoms;
