import { useAtom } from "jotai";
import { openedChestId } from "./atoms/atoms";

import { useChestStore } from "./chestContext/ChestStore";

const useChestBox = () => {
  const [chestState, dispatch] = useChestStore();
  const [isOpen, setIsOpen] = useAtom(openedChestId);

  return {
    isOpen,
    setIsOpen,
    chestState,
  };
};

export default useChestBox;
