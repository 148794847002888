export const BASIC_TYPES = {
  LOAD_BASIC: "LOAD_BASIC",
  GET_BASICS: "GET_BASICS",
  GET_BASIC: "GET_BASIC",
  DELETE_BASIC: "DELETE_BASIC",
  UPDATE_BASIC_DATA: "UPDATE_BASIC_DATA"
};

const initialState = {
  isLoading: false,
  items: [],
  item: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case BASIC_TYPES.LOAD_BASIC:
      return {
        ...state,
        isLoading: action.payload
      };
    case BASIC_TYPES.GET_BASICS:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };
    case BASIC_TYPES.GET_BASIC:
      return {
        ...state,
        item: action.payload,
        isLoading: false
      };
    case BASIC_TYPES.DELETE_BASIC:
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.payload),
        isLoading: false
      };
    case BASIC_TYPES.UPDATE_BASIC_DATA:
      return {
        ...state,
        item: { ...state.item, [action.payload.key]: action.payload.value },
        isLoading: false
      };
    case BASIC_TYPES.RESET_BASIC:
      return {
        ...state,
        item: initialState.item,
        items: initialState.items
      };

    default:
      return state;
  }
}
