import React, { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import Table from "../Table/Table";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import IconWithBackground from "../IconWithBackground/IconWithBackground";
import ModalBtn from "../ModalBtn";

const OrderTable = ({ orders, setSelectedOrder }) => {
  const [state, dispatch] = useStore();

  return orders ? (
    <Table
      datas={orders}
      fields={[
        {
          name: "Référence",
          path: "reference",
        },
        {
          name: "Date",
          path: "createdAt",
          render: (elem) => DateTime.fromISO(elem).toFormat("dd/MM/yyyy"),
        },
        {
          name: "Documents",
          path: "orders",
          render: (elem) => elem.length,
        },
        {
          name: "Montant TTC",
          path: "amount",
          render: (elem) => Math.round(parseFloat(elem) * 100) / 10000 + "€",
        },

        {
          name: "",
          path: "*",
          render: (elem) => (
            <div className="d-flex flex-row align-items-center  justify-content-end">
              <ModalBtn
                btnClass="btn btn-sm  d-flex align-items-center"
                id="order-modal"
                title="Voir la commande"
                label={
                  <>
                    <i style={{ fontSize: 22 }} className="fa fa-eye mr-2" />
                  </>
                }
                onClick={() => setSelectedOrder(elem)}
              />
              <a
                target="_BLANK"
                href={elem.receiptStripeUrl}
                className="btn btn-sm  d-flex align-items-center"
                title="Voir la facture"
              >
                <i
                  style={{ fontSize: 22 }}
                  className="fa fa-file-invoice-dollar"
                />
              </a>
            </div>
          ),
        },
      ]}
    />
  ) : (
    <Loader />
  );
};

export default OrderTable;
