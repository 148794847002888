import React from "react";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";

const BtnListInput = (props) => {
  const { name, value, k, btnInline, datas, dataIndex, change } = props;

  const valueChange = (e, val, isExclusive) => {
    var filteredValue = val;
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        k: props.k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props}>
      <div
        className={
          "my-2 d-flex flex-fill " + (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")
        }
      >
        {datas.map((data, dk) => {
          let isActive = data[dataIndex] == value;
          return (
            <ButtonItem
              key={"btnlist" + props.name + props.id + dk}
              {...props}
              isActive={isActive}
              data={data}
              dk={dk}
              valueChange={valueChange}
            />
          );
        })}
      </div>
    </LabelContainer>
  );
};

export default BtnListInput;
