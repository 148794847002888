import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import {
  errorsAtom,
  filteredSubscriptionsAtom,
  isLoadingsubscriptionsAtom,
  subscriptionsAtom,
  subscriptionsFiltersAtom,
} from "./atoms/atoms";

const useSubscriptions = (autoLoad = false) => {
  const [subscriptions, setSubscriptions] = useAtom(subscriptionsAtom);
  const [subscriptionsFilters, setSubscriptionsFilters] = useAtom(subscriptionsFiltersAtom);
  const [filteredSubscriptions] = useAtom(filteredSubscriptionsAtom);
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useAtom(isLoadingsubscriptionsAtom);
  const [isInit, setIsInit] = useState(false);
  const [errors, setErrors] = useAtom(errorsAtom);

  useEffect(() => {
    if (autoLoad) {
      setSubscriptions([]);
      getSubscriptions();
    }
  }, []);

  const getSubscriptions = () => {
    setIsLoadingSubscriptions(true);
    dataService.get(
      `subscriptions/mines`,
      (subs) => {
        setSubscriptions(subs);
        setIsInit(true);
      },
      setErrors,
      () => setIsLoadingSubscriptions(false)
    );
  };

  const handleChangeFilters = (e) => {
    const { name, value } = e.target;
    setSubscriptionsFilters({ ...subscriptionsFilters, [name]: value });
  };

  return {
    subscriptions,
    subscriptionsFilters,
    filteredSubscriptions,
    isLoadingSubscriptions,
    isInit,
    actions: { getSubscriptions, setSubscriptions, setSubscriptionsFilters, handleChangeFilters },
  };
};

export default useSubscriptions;
