import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import Control from "../Controls/Control";
import Loader from "../Loader";
import SaveBtn from "../SaveBtn";
import useDocumentUploader from "./useDocumentUploader";

const DocumentUploader = ({ chestId = null, onUploaded = () => {} }) => {
  useEffect(() => {
    document.querySelector(".doc-upload-container").scrollIntoView();
  }, []);

  const {
    documentMetadata,
    updateDocumentMeta,
    getRootProps,
    getInputProps,
    isDragActive,
    handleSubmit,
    errors,
    isUploading,
    FILE_TYPES,
    clearFile,
    progress,
    isOpen,
    setIsOpen,
    file,
    setFile,
  } = useDocumentUploader({ chestId, onUploaded });

  return (
    <AnimatePresence exitBeforeEnter>
      {isUploading ? (
        <div className="d-center bg-verylight" style={{ minHeight: 140 }}>
          <Loader text={`Envoi en cours ${progress}%`} />
        </div>
      ) : (
        <div className="mt-4 mb-5 w-100 px-3 doc-upload-container">
          <div
            style={{ fontSize: 22 }}
            className="text-secondary d-flex justify-content-md-between justify-content-start w-100 flex-md-row flex-column mb-2"
          >
            <strong className="mr-2" style={{ fontSize: 24, fontWeight: "550" }}>
              Envoyer un document
            </strong>
          </div>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            style={{ overflow: "hidden" }}
            className="row mx-0   "
          >
            <div className="col-12 col-md-4 pl-0">
              <Control
                label="Type de document"
                type="select"
                value={documentMetadata.type}
                name="type"
                dataIndex="id"
                dataLabel="name"
                datas={FILE_TYPES}
                change={updateDocumentMeta}
                error={errors}
                className="bg-white"
              />
              <Control
                label="Nom"
                type="text"
                value={documentMetadata.label}
                name="label"
                change={updateDocumentMeta}
                error={errors}
                className="bg-white"
              />
            </div>
            <div className="col-12 col-md-6 mt-2 mt-md-0 mb-3 mb-md-0 ">
              <div
                className="bg-white rounded w-100 h-100 d-center text-center cursor-pointer text-secondary"
                style={{
                  border: file ? "none" : "2px dashed",
                }}
              >
                {file ? (
                  <div className="d-center flex-column">
                    <i className="fa fa-3x fa-file mb-2" />
                    {file.name}
                    <button className="btn btn-link" onClick={clearFile}>
                      Retirer ce fichier
                    </button>
                  </div>
                ) : (
                  <div
                    {...getRootProps()}
                    className="w-100 d-center p-3"
                    style={{ minHeight: 140 }}
                  >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div>Déposez le document ici ...</div>
                    ) : (
                      <div>
                        Glissez-déposez un fichier ici,
                        <br /> ou cliquez pour parcourir vos dossiers
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="d-center flex-column h-100">
                <button
                  className="btn btn-secondary d-center w-100 h-100 flex-fill flex-column shadow-sm"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-upload fa-2x mb-2" />
                  <span>Envoyer</span>
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default DocumentUploader;
