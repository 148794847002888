import axios from "axios";
import { API_URL, PLATFORM } from "../../config";
import { NOTIFICATION_TYPES } from "../reducers/notificationReducers";

export const notificationActions = (state, dispatch, props) => {
  function getNotifications() {
    dispatch({ type: NOTIFICATION_TYPES.LOAD_NOTIFICATION, payload: true });

    // dispatch({
    //   type: NOTIFICATION_TYPES.GET_NOTIFICATIONS,
    //   payload: [
    //     {
    //       id: "1",
    //       type: "DOCUMENT_EXPIRED",
    //       entityParams: {
    //         fileId: "5ff86d50525e9677b80d21b4",
    //         name: "extrait KBIS",
    //         expiredAt: "2021-01-12T09:17:17.022Z",
    //         companyName: "10positif",
    //       },
    //     },
    //     {
    //       id: "2",
    //       type: "DOCUMENT_EXPIRED",
    //       entityParams: {
    //         fileId: "5ff86d50525e9677b80d21b4",
    //         name: "extrait KBIS",
    //         expiredAt: "2021-01-12T09:17:17.022Z",
    //         companyName: "10positif",
    //       },
    //     },
    //   ],
    // });

    axios
      .get(API_URL + "notifications/mines")
      .then((res) => {
        dispatch({
          type: NOTIFICATION_TYPES.GET_NOTIFICATIONS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return {
    getNotifications,
  };
};
