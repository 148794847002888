import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn";
import useContact from "./useContact";

const Contact = () => {
  const { handleChange, contactObj, errors, onSubmit, isLoading, isSent } = useContact();
  const [state] = useStore();
  const { CONTACT_FORM_SUBJECTS } = state.constants.items;

  return (
    <Page
      errors={errors}
      title=""
      breadcrumb={[
        { name: "Accueil", path: "/" },
        { name: "Mon compte", path: "/account" },
        { name: "Contact", path: "/contact" },
      ]}
    >
      {isSent && (
        <div
          className="d-center  rounded
         p-3 text-center flex-column mx-auto shadow-sm border animated fadeIn"
          style={{ maxWidth: 400 }}
        >
          <h4 className="pt-0 pb-3">Message envoyé !</h4>
          <p>Nos équipes mettent tout en oeuvre pour y répondre rapidement.</p>
          <Link to="/" className="btn btn-link text-secondary">
            <i className="fa fa-arrow-left mr-1" />
            Revenir à l'accueil
          </Link>
        </div>
      )}
      {!isSent && (
        <form onSubmit={onSubmit}>
          <Control
            label="Objet"
            name="subject"
            type="select"
            datas={CONTACT_FORM_SUBJECTS}
            dataIndex="id"
            dataLabel="name"
            value={contactObj.subject}
            change={handleChange}
            error={errors}
          />
          <Control
            label="Message"
            name="message"
            type="textarea"
            value={contactObj.message}
            change={handleChange}
            error={errors}
            after={
              contactObj.message.length < 20 && (
                <span className="text-info">20 caractères minimum</span>
              )
            }
          />
          <div className="d-flex justify-content-end">
            <SaveBtn
              typeBtn="submit"
              isSaving={isLoading}
              text="Envoyer"
              style={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }}
            />
          </div>
        </form>
      )}
    </Page>
  );
};

export default Contact;
