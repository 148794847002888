import React, { useEffect } from "react";
import useStore from "../../../../../../context/useStore";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";
import Table from "../../../../../common/Table/Table";
import useInvoices from "./useInvoices";
import { colors } from "../../../../../../helpers/colors";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const Invoices = () => {
  const [state] = useStore();
  const { subState, getInvoices, isLoading } = useInvoices(true);

  const { INVOICE_STATUS } = state.constants.items;

  useEffect(() => {
    getInvoices();
  }, [subState.id]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <strong style={{ fontSize: 22 }}>Factures</strong>
      <Table
        fields={[
          {
            name: "Réf",
            path: "*",
            render: (el) => {
              return (
                <div>
                  {el.createdAt && (
                    <div>{DateTime.fromISO(el.createdAt).toFormat("dd/MM/yyyy")}</div>
                  )}
                  <small>{el.reference}</small>
                </div>
              );
            },
          },
          {
            name: "TTC",
            path: "amount",
            render: (el) => {
              return `${tools.truenumberWithSpaces(el / 100)}€`;
            },
          },
          {
            name: "Etat",
            path: "*",
            render: (el) => {
              if (!el.id) return null;
              var ref = tools.findIn(INVOICE_STATUS, el.status, "id");
              return (
                <>
                  <div className={`p-2 mr-1 badge badge-${ref.color}`} style={{ fontSize: 14 }}>
                    <i className={`fa fa-${ref.icon} mr-2`} />
                    {ref.name}
                  </div>
                  <a target="_blank" href={`/invoice/${el.id}/download`}>
                    Ouvrir
                  </a>
                </>
              );
            },
          },
        ]}
        datas={
          subState.invoices?.length > 0
            ? subState.invoices
            : [
                {
                  reference: "Aucune facture",
                },
              ]
        }
      />
    </>
  );
};

export default Invoices;
