import React from "react";
import { Link } from "react-router-dom";
import useCancelSubscription from "../../useCancelSubscription";

const ActionButtons = () => {
  const { actions } = useCancelSubscription();

  return (
    <div className="d-flex align-items-center justify-content-start">
      <Link to="/subscriptions" className="btn btn-primary">
        Garder mon abonnement
      </Link>
      <button className="btn btn-link text-secondary" onClick={actions.nextStep}>
        Résilier mon abonnement
      </button>
    </div>
  );
};

export default ActionButtons;
