const ControlTools = {
  numberWithSpaces: (value) => {
    value = (value + "").replace(/\D/g, "");
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  floatWithSpaces: (value) => {
    var parts = value.toString().split(".");
    parts = parts.map((p) => p.replace(/\D/g, ""));
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
  },
};

export default ControlTools;
