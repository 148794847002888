import React, { useState } from "react";
import Colors from "../../../theme/variables.scss";
import PresentationContent from "./PresentationContent";
import ModalNavigation from "./ModalNavigation";
import useStore from "../../../context/useStore";
import { modalActions } from "../../../context/actions/modalActions";

const ModalPresentation = () => {
  const [state, dispatch] = useStore();
  var modal_actions = modalActions(state, dispatch);
  const slides = [
    {
      id: 0,
      image: "icon-pres-1.svg",
      title: (
        <>
          Lega<span className="text-primary">Drive</span> fait peau neuve !
        </>
      ),
      text: "Une nouvelle interface avec de nouvelles fonctionnalités pour vous simplifier encore plus votre vie professionnelle",
    },
    {
      id: 1,
      image: "icon-pres-2.svg",
      title: <>Tous vos documents professionnels accessibles en un clin d'oeil</>,
      text: "LegaDrive se charge de récupérer vos documents officiels",
    },

    {
      id: 2,
      image: "icon-pres-4.svg",
      title: <>Organisez vos documents par société !</>,
      text: "Téléchargez et stockez tous vos documents officiels",
    },
    {
      id: 3,
      image: "icon-pres-3.svg",
      title: <>Gagnez du temps et partagez vos documents professionnels simplement et en toute sécurité.</>,
      text: "En un simple clic, partagez à vos collaborateurs, ou à des organismes tiers, tout en gardant le contrôle de vos données, qui restent hébergées dans votre coffre LegaDrive",
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [previousSlide, setPreviousSlide] = useState(0);

  const changeSlide = (direction) => {
    if (currentSlide == slides.length - 1 && direction == 1) {
      closeModal();
    } else {
      setPreviousSlide(currentSlide);
      setCurrentSlide((s) => s + direction);
    }
  };

  const closeModal = () => {
    localStorage.setItem("presentationViewed", "1");
    modal_actions.updateModal({
      isOpen: false,
    });
  };

  return (
    <div
      style={{
        background: Colors.light,
        overflowX: "hidden",
        borderRadius: 10,
      }}
      className="d-flex  position-relative flex-column shadow-lg modal-pres"
    >
      <div
        className="d-flex flex-row position-relative"
        style={{
          width: slides.length * 100 + "%",
          transition: "400ms",
          left: -currentSlide * 100 + "%",
          background: "#E2E8ED",
        }}
      >
        {slides.map((slide, sk) => (
          <PresentationContent
            key={"slide" + sk}
            nbSlides={slides.length}
            {...slide}
            isActive={sk == currentSlide}
            previousSlide={previousSlide}
            currentSlide={currentSlide}
          />
        ))}
      </div>
      <ModalNavigation
        slides={slides}
        previousSlide={previousSlide}
        currentSlide={currentSlide}
        changeSlide={changeSlide}
        closeModal={closeModal}
      />
    </div>
  );
};

export default ModalPresentation;
