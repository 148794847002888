import React from "react";
import useCancelSubscription from "../useCancelSubscription";
import NavigationBlock from "./NavigationBlock";
import "./navigation.scss";

const Navigation = () => {
  const { step, maxStep } = useCancelSubscription();

  const blockNames = ["Abonnement", "Votre avis", "À bientôt"];

  return step == maxStep ? null : (
    <div className="py-4 text-center">
      <ul className="d-flex flex-row px-md-2 mb-0 justify-content-center px-0">
        {blockNames.map((block, bk) => {
          const isActive = step == bk;
          const isComplete = step > bk;
          const blockName = block;
          return bk <= maxStep ? (
            <li className="d-flex flex-fill" style={{ maxWidth: 375 }} key={`navblock${block}`}>
              <NavigationBlock
                className={bk == 0 ? "first" : bk == maxStep ? "last" : ""}
                blockName={blockName}
                blockIndex={bk}
                isActive={isActive}
                isComplete={isComplete}
              />
            </li>
          ) : null;
        })}
      </ul>
    </div>
  );
};

export default Navigation;
