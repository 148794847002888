import React from "react";
import useStore from "../../../../../../context/useStore";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Controls/Control";

const SelecDuration = ({
  selectedDuration,
  setSelectedDuration,
  setStep,
  createShare,
  selectedFiles,
}) => {
  const [state, dispatch] = useStore();

  const { LINK_DEADLINES } = state.constants.items;

  const handleChangeDuration = (e) => {
    setSelectedDuration(e.target.value);
  };
  return (
    <>
      <div style={{ fontSize: 16 }} className="text-secondary mt-4">
        Sélectionnez la durée de partage
      </div>

      <Control
        label="Partager pendant :"
        value={selectedDuration}
        change={handleChangeDuration}
        datas={LINK_DEADLINES}
        dataIndex="id"
        dataLabel="name"
        type="btnList"
        btnInline
      />
    </>
  );
};

export default SelecDuration;
