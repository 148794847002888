import React, { useEffect, useState } from "react";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Colors from "../../../../theme/variables.scss";
import SaveBtn from "../../../common/SaveBtn";

const SearchCompany = ({ selectedCompany, setSelectedCompany }) => {
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [noResult, setNoResult] = useState(false);

  const searchCompany = () => {
    if (isSearching) return false;
    setNoResult(false);
    setIsSearching(true);
    setCompanies([]);

    dataService.get(
      `companies/search/${search}`,
      (datas) => {
        datas.length == 0 ? setNoResult(true) : setCompanies(datas);
      },
      setErrors,
      () => setIsSearching(false)
    );
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          searchCompany();
        }}
        className={`d-flex flex-column animated ${selectedCompany ? "fadeOutDown" : "fadeInUp"}`}
      >
        <div className="font-weight-bold">Recherchez une société</div>
        <div className="d-center flex-row w-100">
          <input
            type="text"
            className="d-flex flex-fill border px-3"
            placeholder={"SIREN, Raison sociale, Adresse, etc..."}
            style={{ height: 53 }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          ></input>
          <SaveBtn typeBtn="submit" type="primary" isSaving={isSearching} text="Rechercher" />
        </div>
      </form>

      <div className="row">
        <div className="col-12">
          <ul className="list-group shadow-sm">
            {companies
              .filter((c) => (selectedCompany ? c.siren == selectedCompany.siren : true))
              .map((c, ck) => (
                <div
                  id={"company" + ck}
                  key={"comp" + ck}
                  className={"list-group-item company_card animated fadeInUp faster"}
                  style={{ animationDelay: ck * 50 + "ms" }}
                >
                  <div className="row">
                    <div className="col-12  col-lg-8">
                      <div style={{ fontSize: 16, fontWeight: "700" }}>
                        <i className="fa fa-folder-open mr-2" />
                        {c.companyName}
                      </div>
                      <div className="text-muted" style={{ fontSize: 13 }}>
                        {c.legalForm} - ({c.ape})
                      </div>
                      <div className="text-muted mb-2" style={{ fontSize: 13 }}>
                        {c.activity}
                      </div>

                      <p
                        className="mb-0"
                        style={{
                          fontSize: 15,
                          fontWeight: "300",
                          textTransform: "lowercase",
                        }}
                      >
                        {c.address1} {c.address2} {c.postalCode} {c.city}
                      </p>
                    </div>
                    <div
                      className="col-12  col-lg-3 align-items-center justify-content-center d-flex px-0 my-3"
                      style={{
                        color: Colors.secondary,
                        fontWeight: 700,
                        fontSize: 17,
                      }}
                    >
                      {tools.formatSiret(c.siret)}
                    </div>
                    <div className="col-12  col-lg-1 align-items-center justify-content-center d-flex">
                      {selectedCompany && selectedCompany.siren == c.siren ? (
                        <i
                          className="fa fa-check bg-success text-white p-1"
                          style={{
                            borderRadius: "100%",
                          }}
                        />
                      ) : (
                        <button
                          className="btn btn-outline-primary d-center flex-column"
                          onClick={(e) => setSelectedCompany(c)}
                        >
                          <i className="fa fa-plus" style={{ fontSize: 22, cursor: "pointer" }} />
                          Ajouter
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </ul>
        </div>
      </div>
      {noResult && (
        <div className="row">
          <div className="col-12">
            <p>Désolé, nous n'avons pas trouvé votre entreprise.</p>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchCompany;
