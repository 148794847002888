import React from "react";
import FileShareItem from "./FileShareItem";

const SelecFilesScreen = ({ files, selectedFiles, setSelectedFiles, closeModal, setStep }) => {
  const addFile = (file) => {
    setSelectedFiles([...selectedFiles, file]);
  };

  const removeFile = (file) => {
    setSelectedFiles([...selectedFiles.filter((f) => f.tid != file.tid)]);
  };

  const isFileSelected = (file) => {
    return selectedFiles.find((f) => f.tid == file.tid);
  };

  const addAll = () => {
    setSelectedFiles(files.filter((f) => f.fileId || f.externalUrl));
  };
  const removeAll = () => {
    setSelectedFiles([]);
  };

  return (
    <>
      <div style={{ fontSize: 16 }} className="text-secondary">
        Sélectionnez les documents à partager
      </div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-sm btn-default mr-1" onClick={addAll}>
          Tout ajouter
        </button>
        <button className="btn btn-sm btn-default" onClick={removeAll}>
          Tout retirer
        </button>
      </div>
      <div className="mt-3">
        {files.map((file) => {
          if (!file.fileId && !file.externalUrl) return null;
          var isSelected = isFileSelected(file);
          return (
            <FileShareItem
              file={file}
              isSelected={isSelected}
              addFile={addFile}
              removeFile={removeFile}
            />
          );
        })}
      </div>
    </>
  );
};

export default SelecFilesScreen;
