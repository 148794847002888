import React, { useEffect, useState } from "react";
import useStore from "../../../context/useStore";
import Control from "../../common/Controls/Control";
import DocumentCard from "../../common/DocumentCard/DocumentCard";
import DropFile from "../../common/DropFile";
import Loader from "../../common/Loader";
import DropId from "./DropId";
import dataService from "../../../helpers/dataService";
import SaveBtn from "../../common/SaveBtn";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import AllDone from "./AllDone";

const VerifyAccount = () => {
  const [state, dispatch] = useStore();
  const constants = state.constants.items;

  const [docType, setDocType] = useState("ID");
  const [errors, setErrors] = useState({});
  const [me, setMe] = useState({});
  const [birthdate, setBirthdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [doneStatus, setDoneStatus] = useState(false);

  useEffect(() => {
    getDatas();
  }, []);

  const getDatas = () => {
    setIsLoading(true);
    dataService.get(
      "customers/me",
      (datas) => {
        setMe(datas);
        if (datas.birthdate) {
          setBirthdate(datas.birthdate);
        }
      },
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const save = () => {
    setIsLoading(true);
    dataService.patch(
      "customers/me",
      me,
      (datas) => {
        toast.success("Votre date de naissance a bien été enregistrée");
        setMe(datas);
        setBirthdate(datas.birthdate);
      },
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  const onDocumentUploaded = (result) => {
    setDoneStatus(result);
  };

  return isLoading ? (
    <div className="d-center w-100 mt-5">
      <Loader />
    </div>
  ) : doneStatus ? (
    <AllDone datas={doneStatus} />
  ) : (
    <>
      <div className="shadow-lg p-5 w-100">
        {Object.keys(errors).length ? (
          <div className="alert alert-danger">{errors.other}</div>
        ) : null}
        {!birthdate ? (
          <>
            <Control
              label="Entrez votre date de naissance"
              type="date"
              value={me.birthdate}
              change={(e) => setMe({ ...me, birthdate: e.target.value })}
              error={errors}
            />
            <SaveBtn save={save} isSaving={isLoading} />
          </>
        ) : Object.keys(constants).length ? (
          <>
            <h4>Photo de votre carte d'identité </h4>
            <div className="row mb-3 animated fadeInUp faster">
              <div className="col-12 col-md-8">
                <div className="alert alert-warning h-100 d-center">
                  <ul className="mb-0">
                    <li>
                      Pour vérifier votre compte, vous devez fournir une photo
                      de votre carte d'identité
                    </li>
                    <li>
                      Votre nom, prénom et date de naissance doivent être
                      lisibles
                    </li>
                    <li>La photo doit être au format JPEG ou PNG</li>
                    <li>
                      Vos informations ne sont utilisées qu'à des fins de
                      vérification
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-md-4 mt-3 mt-md-0">
                Exemple de photo acceptée :{" "}
                <img className="img-fluid" src="/images/piece-identite.jpg" />
              </div>
            </div>
            <div className="d-center position-relative">
              <i className="fa fa-chevron-down fa-3x text-tertiary" />
            </div>
            {/* <Control
            label="Pièce d'identité"
            type="btnList"
            btnInline={true}
            value={docType}
            change={(e) => setDocType(e.target.value)}
            datas={constants.PERSONAL_DOCUMENT_TYPES.filter(
              (t) => t.id == "ID"
            )}
            dataIndex="id"
            dataLabel="name"
            error={errors}
            setError={setErrors}
          /> */}
            <DropId type={docType} onDocumentUploaded={onDocumentUploaded} />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default VerifyAccount;
