import axios from "axios";
import { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import useStore from "../../../context/useStore";

const useDocumentUploader = ({ chestId = null, onUploaded = () => {} }) => {
  const [state, dispatch] = useStore();
  const [isUploading, setIsUploading] = useState(false);
  const [documentMetadata, setDocumentMetadata] = useState({
    label: "",
    type: "",
    file: null,
  });
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (acceptedFiles.length > 0 && allowedTypes.indexOf(acceptedFiles[0].type) != -1) {
      setFile(acceptedFiles[0]);
    } else {
      alert("Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf.");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const updateDocumentMeta = (e) => {
    const { name, value } = e.target;
    setDocumentMetadata({
      ...documentMetadata,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("file", file);
    data.append("type", documentMetadata.type);
    data.append("label", documentMetadata.label);
    data.append("chestId", chestId);

    setIsUploading(true);
    axios
      .post(`${API_URL}files`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted >= progress + 25) {
            setProgress(percentCompleted);
          }
        },
      })
      .then((res) => {
        toast.success("Document enregistré");
        setDocumentMetadata({
          label: "",
          type: "",
        });
        setFile(null);
        onUploaded(res.data);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez réessayer");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  };

  const clearFile = () => {
    setFile(null);
  };

  return {
    documentMetadata,
    file,
    setFile,
    updateDocumentMeta,
    getRootProps,
    getInputProps,
    isDragActive,
    handleSubmit,
    errors,
    isUploading,
    FILE_TYPES: state.constants.items.FILE_TYPES,
    clearFile,
    progress,
    isOpen,
    setIsOpen,
  };
};

export default useDocumentUploader;
