import React from "react";
import { Link } from "react-router-dom";
import "./ctaBox.scss";

const CtaBox = ({ text, link, subText, linkText, btnColor = "secondary", icon = "", bgColor }) => {
  return (
    <Link
      to={link}
      className="col-12 col-md-4 col-lg-3 cta-box mb-2 pr-md-0"
      style={{ minWidth: 350, textDecoration: "none" }}
    >
      <div
        className="rounded shadow-sm border border-light pr-0 pl-3  h-100 d-flex justify-content-between align-items-center flex-fill w-100 overflow-hidden position-relative"
        style={{
          backgroundColor: bgColor,
          overflow: "hidden",
        }}
      >
        <div
          className="py-3 pl-3"
          style={{
            maxWidth: 240,
          }}
        >
          <div style={{ fontSize: 22, lineHeight: "30px" }} className="font-weight-bold text-secondary mb-3">
            {text}
          </div>
          {subText && (
            <div style={{ fontSize: 16 }} className="text-secondary mb-3">
              {subText}
            </div>
          )}
          <button className={`btn btn-${btnColor} btn-sm d-inline-flex align-items-center`}>{linkText}</button>
        </div>
        <div
          className="d-flex flex-fill h-100 overflow-hidden position-absolute"
          style={{
            right: -40,
          }}
        >
          <div
            className=" h-100 cta-box-image"
            style={{
              width: 200,
              backgroundImage: `url(/images/${icon})`,
            }}
          ></div>
        </div>
      </div>
    </Link>
  );
};

export default CtaBox;
