import Axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../config";

const dataService = {
  get: (
    url,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.get(API_URL + url)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  post: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  patch: (
    url,
    postData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.patch(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  remove: (
    url,
    deleteData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.delete(API_URL + url, deleteData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  errorDisplay: (err, errorCallback) => {
    if (
      err.response &&
      err.response.data &&
      Object.keys(err.response.data).length > 0 &&
      typeof err.response.data === "object"
    ) {
      Object.values(err.response.data).forEach((err) => toast.error(err));
      errorCallback(err.response.data);
    } else {
      errorCallback({ other: "Une erreur est survenue" });
    }
  },
};

export default dataService;
