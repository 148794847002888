import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import dataService from "../../../helpers/dataService";
import tools from "../../../helpers/tools";
import Loader from "../../common/Loader";
import "./invoice.css";

const DownloadInvoice = (props) => {
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInvoice();
  }, []);

  const print = (data) => {
    document.title = `Legadrive - ${data.reference}`;
    window.print();
  };

  const getInvoice = () => {
    const invoiceId = props.match?.params?.id;

    //call
    if (!invoiceId) {
      return null;
    }
    setIsLoading(true);
    dataService.get(
      `invoices/${invoiceId}`,
      (data) => {
        setInvoice(data);
        setTimeout(() => {
          print(data);
        }, 1000);
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  const getInvoiceDateStatus = () => {
    switch (invoice?.status) {
      case "PAID":
        return (
          <div id="invoice-date">
            <p className="mb-0">
              Date de Facturation :{" "}
              {DateTime.fromISO(invoice.createdAt)
                .setLocale("FR")
                .toFormat("dd LLLL yyyy")}
            </p>
            <p>
              <i className="fa fa-check mr-1" /> Payée le :{" "}
              {DateTime.fromISO(invoice.paidAt)
                .setLocale("FR")
                .toFormat("dd LLLL yyyy")}
            </p>
          </div>
        );
      case "OPEN":
        return (
          <div id="invoice-date">
            <p>
              <i className="fa fa-pause mr-1" /> En attente de paiement
            </p>
          </div>
        );
      case "VOID":
        return (
          <div id="invoice-date">
            <p>
              <i className="fa fa-times mr-1" /> Facture annulée
            </p>
          </div>
        );
    }
    return null;
  };
  return !invoice || isLoading ? (
    <Loader />
  ) : (
    <div>
      <div id="preview-page-container" className="py-5">
        <div className="legacy-page-content" id="preview-page-content">
          <section>
            <div className="align-left" id="pdf-header">
              <img
                style={{
                  maxWidth: 300,
                  maxHeight: 150,
                }}
                src="/images/logo-h.png"
                alt="For pdf logo kbis legadrive octobat"
              />
            </div>
            <div className="align_left">
              <br />
              <p>
                {invoice.creditor.address}
                <br />
                {invoice.creditor.postalCode} {invoice.creditor.city}
                <br />
                {invoice.creditor.email}
                <br />
                www.legadrive.fr
              </p>
            </div>
          </section>
          <div id="invoice-content">
            <section
              id="recipient-address"
              className="d-flex justify-content-start flex-column align-items-end"
            >
              <div>
                <p>
                  <strong className="text-capitalize">
                    {invoice.customer.fullname}
                  </strong>
                </p>
                {invoice.companies &&
                  invoice.companies.map((company) => (
                    <p className="mb-0" key={`comp${company.siren}`}>
                      Société {company.companyName} - {company.siren}
                    </p>
                  ))}
                <p>
                  {invoice.customer.address1}
                  <br />
                  {invoice.customer.address2 ? (
                    <>
                      {invoice.customer.address2}
                      <br />
                    </>
                  ) : null}
                  {invoice.customer.postalCode} {invoice.customer.city}
                </p>
                <p></p>
              </div>
            </section>
            <section id="invoice-headers">
              {getInvoiceDateStatus()}
              <h1>Facture {invoice.reference}</h1>
            </section>

            <section id="invoice-items">
              <table className="item-table table table-bordered">
                <thead>
                  <tr>
                    <th className="first align-left">Description</th>
                    <th className="second align-center">Prix unitaire</th>
                    <th className="third align-center">Qté</th>
                    <th className="fourth align-center">Total HT</th>
                  </tr>
                </thead>
                <tbody>
                  {invoice.products.map((product, pk) => (
                    <tr>
                      <td>{product.name}</td>
                      <td>
                        {tools.numberWithTrailingZero(
                          tools.round(product.unitPrice / 100)
                        )}{" "}
                        €
                      </td>
                      <td>{product.qty}</td>
                      <td>
                        {tools.numberWithTrailingZero(
                          tools.round(product.totalPrice / 100)
                        )}{" "}
                        €
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </section>

            <section
              id="invoice-amounts"
              className="nobreak d-flex flex-column align-items-end"
            >
              <table style={{ width: 400, fontSize: 18 }}>
                <tbody>
                  <tr>
                    <td className="p-2">Total HT</td>
                    <td className="p-2">
                      {tools.numberWithTrailingZero(
                        tools.round(invoice.totalHT / 100)
                      )}{" "}
                      €
                    </td>
                  </tr>

                  {invoice.totalDiscount && (
                    <tr className="text-success">
                      <td className="p-2">{invoice.discountName}</td>
                      <td className="p-2">
                        -
                        {tools.numberWithTrailingZero(
                          tools.round(invoice.totalDiscount / 100)
                        )}{" "}
                        €
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className="p-2">TVA ({invoice.tvaPct} %)</td>
                    <td className="p-2">
                      {tools.numberWithTrailingZero(
                        tools.round(invoice.tvaPrice / 100)
                      )}{" "}
                      €
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="border-top font-weight-bold">
                    <td className="p-2">TOTAL TTC</td>
                    <td className="p-2">
                      {tools.numberWithTrailingZero(
                        tools.round(invoice.totalTTC / 100)
                      )}{" "}
                      €
                    </td>
                  </tr>
                </tfoot>
              </table>
            </section>

            <section id="mentions" className="nobreak">
              <ul></ul>
            </section>

            <section id="payment-methods" className="nobreak"></section>

            <section id="notes" className="nobreak">
              <span></span>
            </section>
          </div>

          <footer
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
            }}
          >
            <div className="footer-text text-center pt-3 border-top">
              {invoice.contact.name}
              <br />
              {invoice.contact.address}, {invoice.contact.postalCode},{" "}
              {invoice.contact.city}, Réunion
              <br />
              {invoice.contact.name} - {invoice.contact.ape} -{" "}
              {invoice.contact.siret} - RCS {invoice.contact.rcs}
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default DownloadInvoice;
