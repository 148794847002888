import React, { useEffect, useState } from "react";
import dataService from "../../../../../../helpers/dataService";
import { populateInvoices } from "../../../subscriptionContext/subscriptionReducer";
import { useSubscriptionStore } from "../../../subscriptionContext/SubscriptionStore";

const useInvoices = (autoLoad) => {
  const [subState, dispatch] = useSubscriptionStore();
  const [isLoading, setIsLoading] = useState(false);

  const getInvoices = () => {
    dataService.get(`subscriptions/${subState.id}/invoices`, (datas) => {
      dispatch(populateInvoices(datas));
    });
  };

  return {
    isLoading,
    subState,
    getInvoices,
  };
};

export default useInvoices;
