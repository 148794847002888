export const NOTIFICATION_TYPES = {
  LOAD_NOTIFICATION: "LOAD_NOTIFICATION",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
};

const initialState = {
  isLoading: false,
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_TYPES.LOAD_NOTIFICATION:
      return {
        ...state,
        isLoading: action.payload,
      };
    case NOTIFICATION_TYPES.GET_NOTIFICATIONS:
      return {
        ...state,
        items: action.payload,
        isLoading: false,
      };

    default:
      return state;
  }
}
