import { toast } from "react-toastify";
import { ERROR_TYPES } from "../context/reducers/errorReducers";
import axios from "axios";
import { DateTime } from "luxon";

const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  copyText: (ref) => {
    ref.current.select();
    ref.current.setSelectionRange(0, 99999);
    //navigator.clipboard.writeText(ref.current);
    document.execCommand("copy");
  },
  copyToClipboard: (text) => {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  },
  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: ERROR_TYPES.GET_ERRORS, payload: errorData });
    }
    return errorData;
  },
  getFullname: (person) => {
    return person
      ? (person.gender ? (person.gender == 1 ? "Mr " : "Mme ") : "") +
          person.firstname +
          " " +
          person.lastname
      : "";
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  validateEmail(mail) {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail)) {
      return true;
    }

    return false;
  },
  validateTel(tel) {
    return tel.match(/^\d{10}$/);
  },
  getFullAddress(addr) {
    return (
      addr.address +
      " - " +
      addr.location.postalCode +
      ", " +
      addr.location.name
    );
  },
  formatSiren: (siren) => {
    if (!siren) return "";
    var str = siren.split("");
    return (
      str[0] +
      str[1] +
      str[2] +
      " " +
      str[3] +
      str[4] +
      str[5] +
      " " +
      str[6] +
      str[7] +
      str[8]
    );
  },
  formatSiret: (siren) => {
    if (!siren) return "";
    var str = siren.split("");
    return (
      str[0] +
      str[1] +
      str[2] +
      " " +
      str[3] +
      str[4] +
      str[5] +
      " " +
      str[6] +
      str[7] +
      str[8] +
      " " +
      str[9] +
      str[10] +
      str[11] +
      " " +
      str[12] +
      str[13]
    );
  },
  truenumberWithSpaces: (x) => {
    x = isNaN(x) ? "" : x + "";
    x = x.replace(/\s/g, "");
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  },
  findIn: (arr, value, attribute) => {
    if (!arr || !value || !attribute) return {};

    var res = arr.find((d) => d[attribute] == value);

    if (res) {
      return res;
    }
    return {};
  },

  numberWithTrailingZero: (x) => {
    if (isNaN(x)) return "";
    var y = (x + "").split(".");
    if (y[1] && y[1].length < 2) {
      y[1] = y[1] + 0;
    }
    if (!y[1]) {
      y[1] = "00";
    }
    return y.join(".");
  },
  round: (value) => {
    if (!value) return 0;
    return Math.round(parseFloat(value) * 100) / 100;
  },
  formatDate: (date) => {
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
  },
};

export default tools;
