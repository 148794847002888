import "bootstrap";
import React from "react";
import { createRoot } from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import App from "./App";
import "./animate.css";
import { recaptcha_token } from "./config";
import Store from "./context/Store";
import rootReducer from "./context/rootReducer";
import * as serviceWorker from "./serviceWorker";
import "./theme/print.scss";
import "./theme/variables.scss";

const root = createRoot(document.getElementById("root"));
root.render(
  <Store rootReducer={rootReducer}>
    <GoogleReCaptchaProvider reCaptchaKey={recaptcha_token}>
      <App />
    </GoogleReCaptchaProvider>
  </Store>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
