import React from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import { useChestStore } from "../../chestContext/ChestStore";
import DocLines from "./DocLines";
import KbisDropdown from "./KbisDropdown";

const DocumentListView = ({ onDeleted }) => {
  const [state] = useStore();
  const { FILE_TYPES } = state.constants.items;
  const [chestState, dispatch] = useChestStore();

  const files = chestState.files || {};

  return (
    <div>
      <table className="table border-left border-bottom" style={{ background: "#f9f9f9" }}>
        <thead>
          <tr>
            <th className="">Titre</th>
            <th className="">Type</th>
            <th className="">Date envoi</th>
            <th className="">Actions</th>
          </tr>
        </thead>

        <tbody>
          {Object.keys(files).map((filekey, fk) => {
            var myfiles = files[filekey];
            var fileType = tools.findIn(FILE_TYPES, filekey, "id");
            var isKbis = fileType.id == "KBIS";
            return (
              <React.Fragment key={`docfile${filekey}${fileType.name}`}>
                <DocLines files={myfiles} fileType={fileType} onDeleted={onDeleted} />
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DocumentListView;
