import React, { useEffect, useState } from "react";
import tools from "../../../../../helpers/tools";
import DocumentUploader from "../../../../common/DocumentUploader/DocumentUploader";
import { updateFiles } from "../../chestContext/chestReducer";
import { useChestStore } from "../../chestContext/ChestStore";
import DocumentCardView from "./DocumentCardView";
import DocumentListView from "./DocumentListView";
import ShareLinksList from "./SharedLinks/ShareLinksList";

const DocumentList = () => {
  const [chestState, dispatch] = useChestStore();
  const [actionOpened, setActionOpened] = useState("");
  const [view, setView] = useState("");

  useEffect(() => {
    var lsState = tools.getState("documentListView", { view: "list" });

    setView(lsState.view);
  }, []);

  useEffect(() => {
    if (view) {
      tools.saveState("documentListView", "view", view);
    }
  }, [view]);

  const onUploaded = (file) => {
    var newFile = {
      fileId: file.id,
      isOfficial: file.isOfficial,
      labels: [
        {
          label: file.label,
          descriptions: [],
        },
      ],
      uploadedAt: file.uploadedAt,
    };
    var existingFilesType = chestState.files[file.type] || [];
    var newFiles = { ...chestState.files, [file.type]: [...existingFilesType, { ...newFile }] };
    dispatch(updateFiles(newFiles));
  };

  const onDeleted = (fileId, fileType) => {
    var filesInType = chestState.files[fileType.id].filter((f) => f.fileId != fileId);
    var newFiles = { ...chestState.files, [fileType.id]: filesInType };
    dispatch(updateFiles(newFiles));
  };

  return (
    <div
      className="animated fadeInUp faster pb-3 bg-white border-primary  mt-2 "
      style={{
        borderTopLeftRadius: 30,
        boxShadow: "0px 0px 15px -9px #0e2b67",
      }}
    >
      <div className="mb-md-2 mb-5 pl-3 pt-5 pt-md-3 d-flex justify-content-between flex-row">
        <strong className="text-secondary " style={{ fontSize: 20, fontWeight: "550" }}>
          <i className="fa fa-file-alt mr-2" />
          DOCUMENTS
        </strong>

        <div className="d-flex justify-content-end pr-2">
          <div className="btn-group d-flex flex-row" role="group" style={{ maxHeight: 40 }}>
            <button
              title="vue en cartes"
              className={`btn  ${view == "cards" ? "btn-secondary" : "btn-default"} btn-sm`}
              onClick={() => setView("cards")}
            >
              <i className="fa fa-border-all" />
            </button>
            <button
              title="vue en tableau"
              className={`btn ${view == "list" ? "btn-secondary" : "btn-default"} btn-sm`}
              onClick={() => setView("list")}
            >
              <i className="fa fa-list" />
            </button>
          </div>
        </div>
      </div>

      {/* <DocumentCardView /> */}

      {view == "cards" ? (
        <DocumentCardView onDeleted={onDeleted} />
      ) : (
        <DocumentListView onDeleted={onDeleted} />
      )}

      <div className="row mx-0 mb-3 mt-5">
        <div className="col-12 col-md-6 my-1">
          <button
            className={`btn  ${
              actionOpened == "share"
                ? "btn-secondary"
                : "btn-outline-secondary border border-secondary"
            } rounded btn-sm d-center w-100 flex-column`}
            onClick={() => setActionOpened((ac) => (ac == "share" ? "" : "share"))}
          >
            <div className="">
              <i className="fa fa-share mr-2" />
              Partage de documents
            </div>
            <p className="mb-0">
              <small>
                <strong>Avec d'autres personnes grâce à un simple lien.</strong>
              </small>
            </p>
          </button>
        </div>
        <div className="col-12 col-md-6 my-1">
          <button
            className={`btn  ${
              actionOpened == "upload"
                ? "btn-secondary"
                : "btn-outline-secondary border border-secondary"
            } rounded btn-sm d-center w-100 flex-column`}
            onClick={() => setActionOpened((ac) => (ac == "upload" ? "" : "upload"))}
          >
            <div className="">
              <i className="fa fa-upload mr-2" />
              Ajouter des documents
            </div>
            <p className="mb-0">
              <small>
                <strong>Et retrouvez les facilement dans votre coffre.</strong>
              </small>
            </p>
          </button>
        </div>
      </div>

      <div className="my-2 d-flex flex-wrap justify-content-center" style={{ gap: "10px" }}>
        {actionOpened == "upload" && (
          <DocumentUploader chestId={chestState.chest.id} onUploaded={onUploaded} />
        )}
        {actionOpened == "share" && (
          <div className="w-100">
            <ShareLinksList />
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentList;
