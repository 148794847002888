import React, { useState } from "react";
import SaveBtn from "../../../../../../common/SaveBtn";
import PaymentMethodButton from "../PaymentMethodButton";
import usePaymentMethods from "../usePaymentMethods";

const SelectPaymentMethod = ({ onFinish }) => {
  const { subState, updateNewPaymentMethod, isUpdatingPaymentMethod } = usePaymentMethods(onFinish);

  const [newSelectedPM, setNewSelectedPM] = useState(subState.providerData.paymentMethodId);

  return (
    <>
      <PaymentMethodButton
        datas={subState.paymentMethods}
        name="selectedPaymentMethod"
        value={newSelectedPM}
        dataIndex="paymentMethodId"
        change={(e) => setNewSelectedPM(e.target.value)}
      />
      <div className="d-flex justify-content-between">
        <button className="btn btn-link" onClick={onFinish}>
          Annuler
        </button>
        <SaveBtn
          save={() => updateNewPaymentMethod(newSelectedPM)}
          isSaving={isUpdatingPaymentMethod}
        />
      </div>
    </>
  );
};

export default SelectPaymentMethod;
