import tools from "../../helpers/tools";

export const AUTH_TYPES = {
  LOAD_CURRENT_USER: "LOAD_CURRENT_USER",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  CLEAR_CURRENT_USER: "CLEAR_CURRENT_USER",
};

const initialState = {
  isAuthenticated: false,
  user: {},
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_TYPES.LOAD_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !tools.isEmpty(action.payload),
        user: {},
        isLoading: action.payload,
      };
    case AUTH_TYPES.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !tools.isEmpty(action.payload),
        user: action.payload,
        isLoading: false,
      };
    case AUTH_TYPES.CLEAR_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      };
    default:
      return state;
  }
}
