import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import { ToastContainer } from "react-toastify";
import "./sidenav.scss";
import { AUTH_TYPES } from "../../../context/reducers/authReducers";
import Avatar from "../Avatar";
import { motion } from "framer-motion";
import Colors from "../../../theme/variables.scss";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Header = withRouter((props) => {
  const [state, dispatch] = useStore();
  const actions = authActions(state, dispatch);
  const [showSub, setShowSub] = useState(false);
  const breakpoint = 769;
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      if (Math.abs(1 - Math.abs(document.documentElement.clientWidth / dimensions.width)) > 0.04) {
        setDimensions({
          height: document.documentElement.clientHeight,
          width: document.documentElement.clientWidth,
        });
      }
    }, 500);

    window.addEventListener("resize", debouncedHandleResize);
    // window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      // window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    if (dimensions.width) {
      if (dimensions.width <= breakpoint) {
        props.setSivenavOpen(true);
      }
    }
  }, [dimensions]);

  useEffect(() => {
    if (state.auth.user.newImage) {
      setTimeout(() => {
        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: { ...state.auth.user, newImage: false },
        });
      }, 200);
    }
  }, [state.auth]);

  useEffect(() => {
    if (props.sidenavOpen) {
      props.setSivenavOpen(false);
    }
    document.querySelector("body").scrollTo(0, 0);
  }, [props.location.pathname]);

  // useEffect(() => {
  //   window.$("#sidebar").addClass("active");
  // }, [props.location.pathname]);

  var authData = state.auth;
  return authData.isAuthenticated && !authData.user.mustRenewPassword ? (
    <>
      <nav
        id="sidebar"
        className={`noprint ${props.isOpen ? "active" : ""}`}
        // onMouseEnter={() => props.setSivenavOpen(false)}
        // onMouseLeave={() => props.setSivenavOpen(true)}
      >
        <div className="sidebar-header text-center">
          <div className="animated pulse slow d-flex align-items-center justify-content-center flex-column">
            <img height="70" src="/images/logo-white.svg" />
          </div>
        </div>

        <ul className="list-unstyled mt-3 components position-relative">
          {props.sidenavItems.map((m, mk) => (
            <li key={"menuitemside" + mk}>
              {m.path !== "#" ? (
                <NavLink className={`nav-link d-flex align-items-center`} to={m.path}>
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <img className="mr-3" src={"/images/icon-" + m.icon + ".svg"} />
                    <span>{m.name}</span>
                  </span>
                </NavLink>
              ) : (
                <a
                  href="#"
                  style={{ color: "#b3b3b3" }}
                  className={`nav-link d-flex align-items-center`}
                  onClick={() => (showSub == mk ? setShowSub(false) : setShowSub(mk))}
                >
                  <span
                    className="d-center"
                    style={{
                      position: "absolute",
                    }}
                  >
                    <i className="mr-3 fa fa-grip-lines" />
                    <span>{m.name}</span>
                  </span>
                </a>
              )}
              {m.items && (
                <ul
                  className="list-unstyled components position-relative"
                  style={{
                    borderLeft: "2px solid" + Colors.primary,
                  }}
                >
                  {m.items.map((it, itk) => (
                    <motion.li
                      exit={{ opacity: 0, y: -50 }}
                      initial={{ opacity: 0, y: -50 }}
                      animate={{ opacity: 1, y: 0 }}
                      key={"menuitemside" + mk + itk}
                    >
                      <NavLink className="nav-link d-flex align-items-center" to={it.path}>
                        <span
                          className="d-center"
                          style={{
                            position: "absolute",
                          }}
                        >
                          <img className="mr-3" src={"/images/icon-" + m.icon + ".svg"} />
                          <span>{it.name}</span>
                        </span>
                      </NavLink>
                    </motion.li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>
    </>
  ) : (
    <></>
  );
});

export default withRouter(Header);
