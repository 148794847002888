import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import OrderTable from "../../common/OrderTable/OrderTable";
import Loader from "../../common/Loader";
import { API_URL } from "../../../config";
import Axios from "axios";
import Modal from "../../common/Modal";
import OrderDetail from "./OrderDetail";
import { Link } from "react-router-dom";

const Orders = () => {
  const [orders, setOrders] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(false);

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = () => {
    setIsLoading(true);
    Axios.get(API_URL + "payments/mines")
      .then((res) => {
        setOrders(res.data);
      })
      .catch((err) => {
        if (err.response?.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <Page errors={errors}>
      <div className="row">
        <div className="col-12 ">
          <h2 className="border-bottom-light mb-3 d-flex flex-row justify-content-between align-items-center flex-wrap">
            Mes commandes
            <Link className="btn btn-primary btn-sm" to="/command">
              <i className="fa fa-cart-plus mr-2" />
              Commander un document
            </Link>
          </h2>
          {isLoading ? (
            <Loader />
          ) : (
            <OrderTable orders={orders} setSelectedOrder={setSelectedOrder} />
          )}
        </div>
      </div>
      <Modal
        large={true}
        id="order-modal"
        title={"Détail commande"}
        bodyStyle={{ padding: "20px 40px" }}
      >
        {selectedOrder && <OrderDetail order={selectedOrder} />}
      </Modal>
    </Page>
  );
};

export default Orders;
