import React, { useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { withRouter } from "react-router-dom";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";
import tools from "../../../helpers/tools";
import useAtoms from "../../../hooks/useAtoms";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import Register from "../Register/Register";
import ForgotPassword from "./ForgotPassword";
import LoginErrorBox from "./LoginErrorBox";

var timerInterval = null;
var recaptcha;

const Login = (props) => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [register, setRegister] = useState(false);
  const [canLoggin, setCanLoggin] = useState(true);

  const { resetAtoms } = useAtoms();

  useEffect(() => {
    if (props.location?.search == "?forget-pwd") {
      setForgotPassword(true);
    }
    resetAtoms();
  }, []);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();

    setIsLoading(true);
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    actions.loginUser({ login, password, captchaToken: recaptchaToken });
    setCaptchaToken(recaptchaToken);
    setIsLoading(false);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  return (
    <div
      className="container-fluid "
      style={{
        height: "110%",
      }}
    >
      <div
        className="row login-container"
        style={{
          height: "110%",
        }}
      >
        <div className="col-12 col-md-6 col-lg-4  mt-4 mt-lg-0 mt-md-0">
          {!register &&
            (forgotPassword ? (
              <ForgotPassword
                setForgotPassword={setForgotPassword}
                captchaToken={captchaToken}
              />
            ) : (
              <form
                onSubmit={loginHandle}
                className="d-flex align-items-center justify-content-start flex-column mx-auto  login-form mt-5"
              >
                <div className=" text-center">
                  <div className="mb-0 mb-md-5 mt-5 mt-mb-0">
                    <img src="/images/logo.svg" />
                  </div>
                  <h1 className="my-2 my-md-5 pt-5 pb-4 text-secondary main-title">
                    Je me connecte sur LegaDrive
                    <hr className="w-50 mt-4" />
                  </h1>
                </div>
                <Control
                  className="w-75"
                  autoFocus={true}
                  label="Email"
                  name="login"
                  id="login"
                  type="email"
                  value={login}
                  change={(e) => setLogin(e.target.value)}
                  error={state.errors}
                  isValid={tools.validateEmail(login)}
                />
                <div className="big-shadow w-75 m-auto">
                  <Control
                    className="w-100"
                    label="Mot de passe"
                    name="password"
                    id="password"
                    type="password"
                    value={password}
                    change={(e) => setPassword(e.target.value)}
                    error={state.errors}
                    isValid={password.length > 6}
                    showValidation={false}
                  />
                </div>
                <LoginErrorBox
                  timer={timer}
                  timerFinish={timerFinish}
                  canLoggin={canLoggin}
                />
                <div className="w-75 mx-auto d-flex justify-content-end">
                  {canLoggin && (
                    <SaveBtn
                      style={{
                        padding: 10,
                        borderTopRightRadius: 0,
                        borderTopLeftRadius: 0,
                      }}
                      className="animated zoomIn"
                      isSaving={isLoading}
                      text="Je me connecte"
                      type="primary"
                      typeBtn="submit"
                    />
                  )}
                </div>
                <div className="w-75 mx-auto d-flex justify-content-end my-3">
                  <button
                    type="button"
                    style={{ fontSize: 12 }}
                    onClick={() => setForgotPassword(true)}
                    className="btn btn-link p-0"
                  >
                    mot de passe oublié ?
                  </button>
                </div>
                <div className="w-75 mx-auto d-flex justify-content-center mt-2">
                  <button
                    type="button"
                    onClick={() => setRegister(true)}
                    style={{ fontSize: 12 }}
                    className="btn btn-link text-secondary p-0"
                  >
                    Vous n'avez pas de compte LegaDrive ?
                  </button>
                </div>
                {isLoading && <GoogleReCaptcha onVerify={verifyCallback} />}
              </form>
            ))}
          {register && <Register setRegister={setRegister} />}
        </div>
        <div
          className="col-12 col-md-6 col-lg-8 d-none d-md-flex login-sidenav p-0 position-fixed w-100 h-100"
          style={{
            top: 0,
            right: 0,
          }}
        ></div>
      </div>
      <div
        className="mt-4 text-center px-3"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
};

export default withRouter(Login);
