import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./components/common/PrivateRoute";
// Pages
import useStore from "./context/useStore";
import Sidenav from "./components/common/layout/Sidenav";
import Login from "./components/pages/Login/Login";
import Dashboard from "./components/pages/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RenewPassword from "./components/pages/RenewPassword/RenewPassword";
import ActivateAccount from "./components/pages/ActivateAccount/ActivateAccount";
import TopHeader from "./components/common/layout/TopHeader";
import Colors from "./theme/variables.scss";
import Register from "./components/pages/Register/Register";
import Chests from "./components/pages/Chests/Chests";

import Profile from "./components/pages/Profile/Profile";
import CustomModal from "./components/common/CustomModal/CustomModal";
import Orders from "./components/pages/Orders/Orders";
import ChestDetail from "./components/pages/Chests/ChestDetail/ChestDetail";
import Test from "./Test/Test";
import Logout from "./components/pages/Logout/Logout";
import Command from "./components/pages/Command/Command";
import SharedFile from "./components/pages/SharedFile/SharedFile";
import AddChest from "./components/pages/Chests/AddChest/AddChest";
import useAuth from "./hooks/useAuth";
import Account from "./components/pages/Account/Account";
import Subscriptions from "./components/pages/Subscriptions/Subscriptions";
import Contact from "./components/pages/Contact/Contact";
import DownloadInvoice from "./components/pages/DownloadInvoice/DownloadInvoice";
import CancelSubscription from "./components/pages/ActivateAccount/CancelSubscription/CancelSubscription";

window.borderRadius = Colors.borderRadius;

const App = () => {
  const [state, dispatch] = useStore();

  //check si les constantes sont chargées en se basant sur la présence d'éléments dans le state global
  const constantsLoaded = Object.values(state.constants.items).length > 0;

  //stand-alone, se charge de configurer l'authentification (axios) et de connecter (ou reconnecter) le user si un token est trouvé dans le LocalStorage
  const authLoad = useAuth();

  const [sideNavOpen, setSivenavOpen] = useState(true);

  const sidenavItems = [
    {
      name: "Accueil",
      path: "/dashboard",
      icon: "home",
    },
    {
      name: "Coffres",
      path: "/chests",
      icon: "safe",
    },
    {
      name: "Ajouter un coffre",
      path: "/command",
      icon: "paper",
    },
    {
      name: "Mon compte",
      path: "/account",
      icon: "user",
    },
    {
      name: "Déconnexion",
      path: "/logout",
      icon: "logout",
    },
  ];

  return !constantsLoaded ? (
    "..."
  ) : (
    <>
      <Router>
        <div className="wrapper">
          <Sidenav
            sidenavItems={sidenavItems}
            isOpen={sideNavOpen}
            setSivenavOpen={setSivenavOpen}
          />
          <div id="content" className="position-relative">
            <TopHeader />
            <button
              type="button"
              id="sidebarCollapse"
              className={`navbar-btn ${sideNavOpen ? "active" : ""}`}
              onClick={() => {
                setSivenavOpen((s) => !s);
              }}
              style={{
                opacity: state.auth.isAuthenticated ? "1" : "0",
              }}
            >
              <i className="fa fa-bars" />
            </button>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            {/* Same as */}
            <Switch>
              <Route path="/renew-password/:token" exact component={RenewPassword} />

              {process.env.NODE_ENV == "development" && (
                <Route path="/test" exact component={Test} />
              )}
              <Route path="/activate-account/:token/:email" exact component={ActivateAccount} />
              <Route path="/renew-password/:token/:email" exact component={RenewPassword} />
              <Route path="/logout" exact component={Logout} />
              <Route path="/register" exact component={Register} />
              <Route path="/shared/:token" exact component={SharedFile} />
              <PrivateRoute path="/" exact component={Dashboard} />
              <PrivateRoute path="/dashboard" exact component={Dashboard} />
              <PrivateRoute path="/chests" exact component={Chests} />
              <PrivateRoute path="/chests/:id" exact component={Chests} />
              <PrivateRoute path="/account" exact component={Account} />
              <PrivateRoute path="/profile" exact component={Profile} />
              <PrivateRoute path="/orders" exact component={Orders} />
              <PrivateRoute path="/command" exact component={Command} />
              <PrivateRoute path="/command/:action" exact component={Command} />
              <PrivateRoute path="/add-chest" exact component={AddChest} />
              <PrivateRoute path="/subscriptions" exact component={Subscriptions} />
              <PrivateRoute
                path="/subscriptions/:id/cancel/:amount/:createdAt/:echeance"
                exact
                component={CancelSubscription}
              />
              <PrivateRoute path="/subscriptions/:id" exact component={Subscriptions} />
              <PrivateRoute path="/contact" exact component={Contact} />
              <PrivateRoute path="/invoice/:id/download" exact component={DownloadInvoice} />
            </Switch>
          </div>
        </div>
      </Router>
      <CustomModal />
    </>
  );
};

export default App;
