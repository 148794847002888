import React from "react";

const ChestStore = React.createContext();

export const useChestStore = () => React.useContext(ChestStore);

export const ChestStoreProvider = ({ children, initialState, reducer }) => {
  const [chestState, dispatch] = React.useReducer(reducer, initialState);

  return <ChestStore.Provider value={[chestState, dispatch]}>{children}</ChestStore.Provider>;
};
