import React from "react";
import tools from "../../../../../../helpers/tools";
import useCancelSubscription from "../../useCancelSubscription";
import ActionButtons from "./ActionButtons";
import Feature from "./Feature";

const SubscriptionStep = () => {
  const { subscription } = useCancelSubscription();

  console.log(subscription);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12 text-center ">
          <strong className="text-secondary" style={{ fontSize: 22 }}>
            Détail de votre abonnement
          </strong>
        </div>
      </div>

      <div className="row bg-verylight border mx-auto p-4 rounded" style={{ maxWidth: 690 }}>
        <div className="col-12 col-md-5 text-secondary">
          <div className="d-flex align-items-start justify-content-center h-100 flex-column">
            <strong style={{ fontSize: 30 }}>
              {subscription.amount}
              <sup style={{ fontSize: 14, position: "relative", top: -12 }}>€/mois</sup>
            </strong>
            <div>Créé le : {subscription.createdAt}</div>
            <div>Prochaine facturation : {subscription.echeance}</div>
          </div>
        </div>

        <div className="col-12 col-md-7 text-secondary border-left mt-3 mt-md-0 pl-4">
          <strong className="text-secondary mb-2">Vos avantages en tant qu'abonné</strong>

          <Feature>Partagez vos documents rapidement</Feature>
          <Feature>
            Espace de stockage <strong>illimité</strong>
          </Feature>
          <Feature>Renouveler tous vos documents professionnels</Feature>
        </div>
      </div>
      <div className="row mx-auto mt-3" style={{ maxWidth: 690 }}>
        <div className="col-12 pl-0">
          <ActionButtons />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStep;
