import React, { useState } from "react";
import { modalActions } from "../../../../../../context/actions/modalActions";
import useStore from "../../../../../../context/useStore";
import dataService from "../../../../../../helpers/dataService";
import tools from "../../../../../../helpers/tools";
import Loader from "../../../../../common/Loader";
import SelecDuration from "./SelecDuration";
import SelecFilesScreen from "./SelecFilesScreen";
import ShareResultScreen from "./ShareResultScreen";

const ShareModal = ({ chestState, onCreated }) => {
  const [state, gDispatch] = useStore();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState("2");
  const mActions = modalActions(state, gDispatch);
  const [step, setStep] = useState(1);
  const { LINK_DEADLINES } = state.constants.items;
  const [shareResult, setShareResult] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const closeModal = () => {
    mActions.updateModal({
      isOpen: false,
      content: null,
    });
  };

  const createShare = () => {
    setIsLoading(true);
    dataService.post(
      `links`,
      {
        chest: chestState.chest.id,
        files: selectedFiles,
        deadline: selectedDuration,
      },
      (data) => {
        setShareResult(data);
        setStep(4);
        onCreated();
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  var files = [];
  var allFiles = chestState.files;
  Object.values(allFiles).forEach((fileArr, tk) => {
    fileArr.forEach((file, fk) => files.push({ ...file, tid: `${tk}${fk}` }));
  });

  return (
    <div className="bg-white p-3 rounded position-relative">
      <div style={{ fontSize: 24 }} className="mb-2 pr-5">
        <strong className="text-secondary">Coffre de {chestState.chest.companyName}</strong>
      </div>
      {step >= 1 && step < 3 && (
        <>
          <SelecFilesScreen
            files={files}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            closeModal={closeModal}
            setStep={setStep}
          />
          <div className="mt-3">
            <SelecDuration
              selectedDuration={selectedDuration}
              setSelectedDuration={setSelectedDuration}
              setStep={setStep}
              createShare={createShare}
              selectedFiles={selectedFiles}
            />
          </div>
        </>
      )}

      {step == 4 && (
        <div>
          <ShareResultScreen shareResult={shareResult} />
        </div>
      )}

      {selectedFiles.length > 0 && (
        <div className="mt-3 d-flex justify-content-end">
          <button disabled={isLoading} className="btn btn-link text-disabled" onClick={closeModal}>
            {step == 3 ? "Terminer" : "Annuler"}
          </button>
          {step < 3 && (
            <button disabled={isLoading} className="btn btn-primary" onClick={createShare}>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  Partager {selectedFiles.length} document{selectedFiles.length > 1 ? "s" : ""}{" "}
                  {selectedDuration &&
                    `pendant 
            ${tools.findIn(LINK_DEADLINES, selectedDuration, "id").name}`}
                </>
              )}
            </button>
          )}
        </div>
      )}

      <button
        onClick={closeModal}
        className="btn btn-default rounded-circle text-dark d-center"
        style={{
          position: "absolute",
          top: 7,
          right: 7,
          height: 30,
          width: 30,
        }}
      >
        <i className="fa fa-times" />
      </button>
    </div>
  );
};

export default ShareModal;
