import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import PageContainer from "./PageContainer";

const Page = ({
  container = "container",
  containerClassname = "",
  title,
  children,
  errors = null,
  back = null,
  action = null,
  style = { maxWidth: "90%" },
  margin = "m-0 mx-md-auto my-md-5",
  titleMargin = "mb-lg-5 mb-md-4 mb-3",
  notAnimated = false,
  actionBar = null,
  breadcrumb = null,
}) => {
  return (
    <PageContainer
      container={container}
      style={style}
      containerClassname={containerClassname}
      notAnimated={notAnimated}
    >
      {breadcrumb && <Breadcrumb links={breadcrumb} />}
      {actionBar && actionBar}
      {title && (
        <div className={" d-flex align-items-center " + titleMargin}>
          {back && (
            <Link to={back} className="btn mr-3 shadow-none" style={{ padding: "15px 18px" }}>
              <i className="fa fa-arrow-left"></i>
            </Link>
          )}

          <h1
            className="align-items-center d-flex justify-content-between mb-0 mx-0 text-center w-100"
            style={{ fontSize: 23 }}
          >
            {title}
            {action && (
              <Link to={action.to} className="btn btn-primary float-right ml-3">
                <i className="fa fa-plus mr-2"></i>
                {action.text}
              </Link>
            )}
          </h1>
        </div>
      )}
      {errors && errors.other && <div className="alert alert-danger">{errors.other}</div>}
      {children}
    </PageContainer>
  );
};

export default Page;
