import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import Navigation from "./Navigation/Navigation";
import Steps from "./Steps/Steps";
import useCancelSubscription from "./useCancelSubscription";

const CancelSubscription = () => {
  const params = useParams();
  const { actions } = useCancelSubscription();

  useEffect(() => {
    actions.initSubscription({
      id: params.id,
      amount: tools.truenumberWithSpaces(parseInt(params.amount) / 100),
      createdAt: tools.formatDate(params.createdAt),
      echeance: tools.formatDate(params.echeance),
    });
  }, []);

  return (
    <Page
      back="/subscriptions"
      breadcrumb={[
        { name: "Accueil", path: "/" },
        { name: "Abonnements", path: "/subscriptions" },
        { name: "Résiliation", path: "/subscriptions/cancel" },
      ]}
    >
      <Navigation />
      <Steps />
    </Page>
  );
};

export default CancelSubscription;
