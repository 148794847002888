import React, { useState } from "react";
import { useElements, useStripe, CardNumberElement, IbanElement } from "@stripe/react-stripe-js";
import dataService from "../../../../../../helpers/dataService";
import {
  changeSelectedPaymentMethod,
  initPaymentMethods,
} from "../../../subscriptionContext/subscriptionReducer";
import { useSubscriptionStore } from "../../../subscriptionContext/SubscriptionStore";
import useStore from "../../../../../../context/useStore";
import { toast } from "react-toastify";

const usePaymentMethods = (onFinish) => {
  const stripe = useStripe();
  const elements = useElements();
  const [state] = useStore();
  const [subState, dispatch] = useSubscriptionStore();
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [paymentErrors, setPaymentErrors] = useState(false);
  const [isCreatingPaymentMethod, setIsCreatingPaymentMethod] = useState(false);
  const [isUpdatingPaymentMethod, setIsUpdatingPaymentMethod] = useState(false);

  const getPaymentMethods = () => {
    setIsLoadingPaymentMethods(true);
    dataService.get(
      `payments/methods/mines`,
      (datas) => {
        dispatch(initPaymentMethods(datas));
      },
      (err) => {},
      () => setIsLoadingPaymentMethods(false)
    );
  };

  const updateNewPaymentMethod = async (paymentMethodId) => {
    var resultUpdateSubPM = null;

    setIsUpdatingPaymentMethod(true);

    resultUpdateSubPM = await updateSubscriptionPaymentMethod(paymentMethodId);

    if (resultUpdateSubPM.error) {
      setPaymentErrors("Erreur interne, veuillez réessayer");
      setIsUpdatingPaymentMethod(false);
      return false;
    }
    dispatch(changeSelectedPaymentMethod(resultUpdateSubPM.providerData));
    toast.success("Méthode de paiement modifiée");
    onFinish();
  };

  const getSetupIntent = async (paymentType) => {
    return new Promise((resolve, reject) => {
      dataService.post(
        `payments/setup-intent`,
        {
          paymentType,
        },
        resolve,
        (err) => resolve({ error: err })
      );
    });
  };

  const setupSepaDebit = async (si) => {
    const iban = elements.getElement(IbanElement);

    const accountholderName = `${state.auth.user.firstname} ${state.auth.user.lastname}`;
    const email = state.auth.user.email;
    return await stripe.confirmSepaDebitSetup(si, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName,
          email: email,
        },
      },
    });
  };

  const setupCard = async (si) => {
    const card = elements.getElement(CardNumberElement);

    const accountholderName = `${state.auth.user.firstname} ${state.auth.user.lastname}`;
    const email = state.auth.user.email;
    return await stripe.confirmCardSetup(si, {
      payment_method: {
        card: card,
        billing_details: {
          name: accountholderName,
          email: email,
        },
      },
    });
  };

  const saveNewPaymentMethod = async (methodType) => {
    var resultCreatePM = null;
    var resultAttachPM = null;
    var resultUpdateSubPM = null;
    var paymentMethodId = null;

    setIsCreatingPaymentMethod(true);

    const csec = await getSetupIntent(methodType);

    switch (methodType) {
      case "CB":
        resultCreatePM = await setupCard(csec.setupIntent);
        break;
      case "SEPA":
        resultCreatePM = await setupSepaDebit(csec.setupIntent);

        break;
    }

    if (resultCreatePM.error) {
      setPaymentErrors(
        `Une erreur est survenue à la mise en place de cette méthode de paiement : ${
          resultCreatePM.error.message || "contactez LegaDrive"
        }`
      );
      setIsCreatingPaymentMethod(false);

      return false;
    }
    paymentMethodId = resultCreatePM.setupIntent.payment_method;

    // resultCreatePM = await createPaymentMethod(methodType);
    // if (resultCreatePM.error) {
    //   setPaymentErrors(resultCreatePM.error.message);
    //   setIsCreatingPaymentMethod(false);
    //   return false;
    // }

    // paymentMethodId = resultCreatePM.paymentMethod.id;

    // resultAttachPM = await attachPaymentMethod(paymentMethodId);
    // if (resultAttachPM.error) {
    //   setPaymentErrors("Erreur interne, veuillez réessayer");
    //   setIsCreatingPaymentMethod(false);
    //   return false;
    // }

    //return false;
    resultUpdateSubPM = await updateSubscriptionPaymentMethod(paymentMethodId);

    if (resultUpdateSubPM.error) {
      setPaymentErrors("Erreur interne, veuillez réessayer");
      setIsCreatingPaymentMethod(false);
      return false;
    }
    dispatch(changeSelectedPaymentMethod(resultUpdateSubPM.providerData));
    getPaymentMethods();
    toast.success("Méthode de paiement créée");
    onFinish();
  };

  const createPaymentMethod = async (methodType) => {
    const paymentSupport =
      methodType == "CB"
        ? { type: "card", card: elements.getElement(CardNumberElement) }
        : { type: "sepa_debit", sepa_debit: elements.getElement(IbanElement) };

    const findAddress = subState.billingAddress;
    const address = {
      city: findAddress?.city,
      country: "FR",
      line1: findAddress?.address1,
      line2: findAddress?.address2,
      postal_code: findAddress?.postalCode,
    };

    const billing_details = {
      name: state.auth.user.lastname,
      email: state.auth.user.email,
      address,
    };
    let paymentMethodData = {
      ...paymentSupport,
      billing_details,
    };

    return await stripe.createPaymentMethod(paymentMethodData);
  };

  const attachPaymentMethod = async (paymentMethodId) => {
    return new Promise((resolve, reject) =>
      dataService.post(
        `user-customers/payment-methods/attach`,
        {
          paymentMethodId,
        },
        resolve,
        (err) => resolve({ error: err })
      )
    );
  };

  const updateSubscriptionPaymentMethod = (paymentMethodId) => {
    return new Promise((resolve, reject) => {
      dataService.patch(
        `subscriptions/${subState.id}/payment-method`,
        {
          paymentMethodId,
        },
        resolve,
        (err) => resolve({ error: err })
      );
    });
  };

  return {
    subState,
    isLoadingPaymentMethods,
    isCreatingPaymentMethod,
    isUpdatingPaymentMethod,
    paymentErrors,
    getPaymentMethods,
    createPaymentMethod,
    attachPaymentMethod,
    saveNewPaymentMethod,
    updateNewPaymentMethod,
  };
};

export default usePaymentMethods;
