import axios from "axios";
import { API_URL, PLATFORM } from "../../config";
import { AUTH_TYPES } from "../reducers/authReducers";
import tools from "../../helpers/tools";
import jwt_decode from "jwt-decode";
import { ERROR_TYPES } from "../reducers/errorReducers";

export const authActions = (state, dispatch) => {
  function loginUser(userData) {
    // fetch api

    axios
      .post(API_URL + "auth/login/" + PLATFORM, userData, {
        withCredentials: true,
      })
      .then((res) => {
        // Save to localStorage
        const token = res.data;
        // Set token to ls
        localStorage.setItem("jwtToken", token);
        // Set token to Auth header
        //tools.setAuthToken(token);
        // Set current user

        dispatch({
          type: AUTH_TYPES.SET_CURRENT_USER,
          payload: jwt_decode(token),
        });
      })
      .catch((err) => {
        tools.filterError(err, dispatch, AUTH_TYPES.LOAD_CURRENT_USER);
      });
  }

  function logoutUser() {
    // Remove token from localStorage

    localStorage.removeItem("jwtToken");
    // Remove auth header for future requests
    axios.get(API_URL + "auth/logout").catch((err) => {
      console.log(err);
    });
    // Set current user to {} which will set isAuthenticated to false
    dispatch({ type: AUTH_TYPES.CLEAR_CURRENT_USER, payload: {} });
    dispatch({ type: ERROR_TYPES.CLEAR_ERRORS, payload: {} });
    //tools.filterAlert("info", "Vous avez été correctement déconnecté.");
  }

  return {
    loginUser,
    logoutUser,
  };
};
