import { AnimatePresence, motion } from "framer-motion";
import { useAtom } from "jotai";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { toast } from "react-toastify";
import useStore from "../../../context/useStore";
import { colors } from "../../../helpers/colors";
import tools from "../../../helpers/tools";
import { openedChestId } from "../../pages/Chests/atoms/atoms";
import { useChestStore } from "../../pages/Chests/chestContext/ChestStore";
import InfosComplete from "./InfosComplete";
import InfosReduced from "./InfosReduced";

const SubStatus = ({ chestState, subStatus }) => {
  const { subscription, isFrozen } = chestState.chest;

  return (
    <>
      {isFrozen && (
        <i
          className="fa fa-lock text-light fa-2x"
          style={{
            position: "absolute",
            top: 10,
            right: 10,
          }}
        />
      )}
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`subscriptions`}
        className={` py-1 px-2 d-center text-black-50`}
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          borderTopLeftRadius: 5,
        }}
      >
        <i
          className={`fa fa-circle mr-1 text-${subStatus.color}`}
          style={{
            fontSize: 12,
          }}
        />
        <small>
          {subStatus.name}{" "}
          {isFrozen
            ? ""
            : ` - échéance le ${DateTime.fromISO(subscription.echeance).toFormat("dd.MM.yyyy")}`}
        </small>
      </Link>
    </>
  );
};

const CompanyInfos = () => {
  const [state] = useStore();
  const [isOpen, setIsOpen] = useAtom(openedChestId);
  const [chestState, dispatch] = useChestStore();

  const { SUBSCRIPTION_STATUS } = state.constants.items;
  let isChestOpen = isOpen == chestState.chest.id;
  var subStatus = tools.findIn(SUBSCRIPTION_STATUS, chestState.chest.subscription.status, "id");

  const openMe = () => {
    if (chestState.chest.isFrozen) {
      toast.info(`Vous ne pouvez pas ouvrir ce coffre car son abonnement est "${subStatus.name}"`);
    } else {
      setIsOpen(chestState.chest.id);
    }
  };

  return (
    <div
      style={{
        background: isChestOpen ? "#e6effe" : "",
      }}
    >
      <AnimatePresence>
        <motion.div
          layout
          className={`col-12   position-relative ${
            isChestOpen ? "" : " bg-verylight cursor-pointer border shadow-sm"
          }`}
          style={{
            maxWidth: isChestOpen ? "100%" : 450,
            borderRadius: isChestOpen ? 0 : "6px",
            overflow: "hidden",
            paddingBottom: 15,
          }}
          onClick={openMe}
        >
          <SubStatus chestState={chestState} subStatus={subStatus} />
          <div className={`row ${isChestOpen ? "p-2" : "p-3"}`}>
            {!isChestOpen && (
              <div className="col-3 col-md-2 col-lg-2 px-3 mb-2 mb-md-0 px-md-0">
                <div
                  className="d-center text-dark "
                  style={{
                    width: "100%",
                    height: 95,
                    backgroundImage: "url(/images/icon-coffre.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                  }}
                ></div>
              </div>
            )}
            <div className={`${isChestOpen ? "col-12 pl-0" : "col-9 col-md-10 col-lg-10 "} `}>
              <div className="row">
                <div
                  className={`col-12 mb-2 ${isChestOpen ? "pt-5 pt-md-3 pl-4" : ""}`}
                  style={{
                    fontSize: isChestOpen ? 20 : 16,
                    fontWeight: "550",
                    color: "midnightblue",
                  }}
                >
                  {/* {isChestOpen ? "Société " : ""} */}
                  {isChestOpen ? <i className="fa fa-building mr-2" /> : null}
                  <span className={isChestOpen ? "text-secondary" : ""}>
                    {chestState.chest.companyName}
                  </span>
                </div>

                <div className="col-12">{isChestOpen ? <InfosComplete /> : <InfosReduced />}</div>
              </div>
            </div>
          </div>
          {chestState.isLoading && (
            <div
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                width: "100%",
              }}
            >
              <BarLoader width={"100%"} color={colors.secondary} />
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default CompanyInfos;
