import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import ImageFileCrop from "../../common/ImageFileCrop/ImageFileCrop";
import Avatar from "../../common/Avatar";
import useStore from "../../../context/useStore";
import Control from "../../common/Control";
import PasswordControl from "../../common/PasswordControl";
import UserInfo from "./UserInfo";
import UnderCursor from "./UnderCursor";
import Colors from "../../../theme/variables.scss";
import VerifyAccount from "./VerifyAccount";
import dataService from "../../../helpers/dataService";

const Profile = (props) => {
  const [state, dispatch] = useStore();

  const [mustCertificate, setMustCertificate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const activeTab = props.location.search != "" ? false : true;
  const [showProfile, setShowProfile] = useState(activeTab);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    //checkCertif();
  }, []);

  const checkCertif = () => {
    setIsLoading(true);
    dataService.get(
      "certifications/me",
      (datas) => {
        setMustCertificate(datas.mustCertificate);
      },
      (error) => setErrors(error),
      () => setIsLoading(false)
    );
  };

  return (
    <Page
      title=""
      breadcrumb={[
        { path: "/", name: "Accueil" },
        { path: "/account", name: "Mon compte" },
        { path: "/profile", name: "Profil" },
      ]}
    >
      {
        <>
          <div className="row" style={{ minWidth: 152 }}>
            {showProfile ? <UserInfo /> : mustCertificate ? <VerifyAccount /> : null}
          </div>
        </>
      }
    </Page>
  );
};

export default Profile;
