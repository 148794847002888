import { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../helpers/dataService";

const useContact = () => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [contactObj, setContactObj] = useState({
    subject: "",
    message: "",
  });
  const [isSent, setIsSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactObj({
      ...contactObj,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    dataService.post(
      `legadrive/contact-form`,
      contactObj,
      (data) => {
        setIsSent(true);
      },
      setErrors,
      () => setIsLoading(false)
    );
  };

  return {
    handleChange,
    onSubmit,
    isSent,
    contactObj,
    errors,
    isLoading,
  };
};

export default useContact;
