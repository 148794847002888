import React, { useRef } from "react";
import { toast } from "react-toastify";
import { APP_URL } from "../../../../../../config";
import tools from "../../../../../../helpers/tools";
import Control from "../../../../../common/Controls/Control";

const ShareResultScreen = ({ shareResult }) => {
  const inpRef = useRef(null);

  const copyLink = () => {
    tools.copyText(inpRef);
    toast.info("Copié dans le presse-papier");
  };

  return (
    <div>
      <div>
        Vous pouvez à présent partager ces documents avec d'autres personnes en leur communiquant le
        lien de partage ci-dessous.
      </div>

      <input
        ref={inpRef}
        onClick={copyLink}
        type="text"
        value={`${APP_URL}shared/${shareResult.token}`}
        change={() => {}}
        className="w-100 border-0 rounded p-2 my-3 bg-default"
        style={{ fontSize: 14 }}
      />
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" onClick={copyLink}>
          <i className="fa fa-copy mr-2" />
          Copier le lien
        </button>
      </div>
    </div>
  );
};

export default ShareResultScreen;
