import React from "react";

const ComingSoon = () => {
  return (
    <div
      className="p-2 mx-2  border rounded d-inline-flex"
      style={{
        background: "rgb(231, 239, 255)",
      }}
    >
      <div className="mb-0 text-secondary px-2 position-relative font-weight-bold">
        <div
          className="d-center bg-white rounded-circle border shadow-sm "
          style={{
            position: "absolute",
            top: -15,
            left: -15,
            width: 23,
            height: 23,
          }}
        >
          <i className="fa fa-stopwatch text-secondary" />
        </div>
        Votre extrait KBIS sera bientôt disponible ici.
      </div>
    </div>
  );
};

export default ComingSoon;
