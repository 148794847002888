import React from "react";

const PageContainer = ({
  container = "container",
  style = { maxWidth: "90%" },
  containerClassname = "",
  notAnimated = false,
  margin = "m-0 mx-md-auto my-md-5",
  ...props
}) => {
  return (
    <div
      className={
        " page-container " +
        container +
        " " +
        margin +
        " " +
        containerClassname +
        (notAnimated ? "" : " animated fadeIn faster ")
      }
      style={style}
    >
      {props.children}
    </div>
  );
};

export default PageContainer;
