import React from "react";

const ActionButton = ({ children, ...props }) => {
  return (
    <button
      className="btn btn-link text-secondary  mt-2 d-inline-flex align-items-center"
      {...props}
    >
      {children}
    </button>
  );
};

export default ActionButton;
