import { atom } from "jotai";

export const subscriptionsAtom = atom([]);
export const subscriptionsFiltersAtom = atom({
  status: [],
});

//state dérivé de subscriptionsAtom ou les filtres de subscriptionsFiltersAtom sont appliqués
export const filteredSubscriptionsAtom = atom((get) => {
  let subs = get(subscriptionsAtom);
  let status = get(subscriptionsFiltersAtom).status;
  return subs.filter((sub) => (status.length > 0 ? status.includes(sub.status) : sub));
});
export const isLoadingsubscriptionsAtom = atom(false);
export const errorsAtom = atom({});
