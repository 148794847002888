import React, { useState } from "react";
import { toast } from "react-toastify";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";
import dataService from "../../../../../helpers/dataService";
import { useChestStore } from "../../chestContext/ChestStore";
import RenewKbisModal from "./RenewKbisModal";

const KbisDropdown = ({ fileType, files }) => {
  const [gState, gDispatch] = useStore();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chestState, dispatch] = useChestStore();
  const mActions = modalActions(gState, gDispatch);

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const openModal = () => {
    mActions.updateModal({
      isOpen: true,
      content: <RenewKbisModal onClick={generateNewKbis} />,
      style: {
        width: 400,
      },
    });
  };

  const generateNewKbis = () => {
    setIsOpen(false);
    if (isLoading) return false;
    setIsLoading(true);
    dataService.post(
      `files/KBIS/reload`,
      { chestId: chestState.chest.id },
      () => {
        toast.success("Votre demande de KBIS a bien été prise en compte.");
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  if (fileType.id != "KBIS" || (files.length && files[0].isComing)) return null;

  return (
    <div className="position-relative" onBlur={handleBlur}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 rounded-circle btn btn-outline-info d-center border border-info "
        style={{
          width: 25,
          height: 25,
        }}
      >
        <i className="fa fa-ellipsis-h" />
      </button>
      {isOpen && (
        <div
          className="arrow-box-up shadow rounded animated fadeIn faster"
          style={{
            position: "absolute",
            top: "150%",
            left: -11,
            width: 200,
            zIndex: "8",
          }}
        >
          <button className="btn btn-secondary w-100 " onClick={openModal}>
            Renouveler mon KBIS
          </button>
        </div>
      )}
    </div>
  );
};

export default KbisDropdown;
