export const CONSTANT_TYPES = {
  LOAD_CONSTANT: "LOAD_CONSTANT",
  GET_CONSTANTS: "GET_CONSTANTS"
};

const initialState = {
  isLoading: false,
  items: [],
  item: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CONSTANT_TYPES.LOAD_CONSTANT:
      return {
        ...state,
        isLoading: action.payload
      };
    case CONSTANT_TYPES.GET_CONSTANTS:
      return {
        ...state,
        items: action.payload,
        isLoading: false
      };

    default:
      return state;
  }
}
