import React from "react";
import useConstants from "../../../../../hooks/useConstants";
import Control from "../../../../common/Controls/Control";
import useSubscriptions from "../../useSubscriptions";

const SubscriptionsFilters = () => {
  const { actions, subscriptionsFilters, subscriptions } = useSubscriptions();
  const { SUBSCRIPTION_STATUS } = useConstants();

  //création d'un nouvel array de statuts à partir de la constante. On ajoute le nombre (count) de subs pour chaque statut et on retire des statuts avec 0 sub
  const countedSubByStatus = SUBSCRIPTION_STATUS.map((sub) => {
    var count = subscriptions.filter((s) => s.status == sub.id).length;
    return count > 0 ? { ...sub, name: `${sub.name} (${count})` } : null;
  }).filter((sub) => sub);

  //on n'affiche les filtres que s'il y a des subs dans plus d'1 statut
  return countedSubByStatus.length > 1 ? (
    <div className="row mb-5">
      <div className="col-12">
        <Control
          label="Filtrer les abonnements par statut :"
          type="checkBtnList"
          value={subscriptionsFilters.status}
          name="status"
          change={actions.handleChangeFilters}
          datas={countedSubByStatus}
          dataIndex="id"
          dataLabel="name"
          dataIcon="icon"
          btnInline
          iconStyle={{
            fontSize: 20,
            width: 20,
          }}
          labelClassname="rounded"
        />
      </div>
    </div>
  ) : null;
};

export default SubscriptionsFilters;
