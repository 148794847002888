import React from "react";
import DocumentCard from "../../common/DocumentCard/DocumentCard";
import { DateTime } from "luxon";
import useStore from "../../../context/useStore";
import Loader from "../../common/Loader";
import tools from "../../../helpers/tools";

const OrderDetail = ({ order }) => {
  const [state, dispatch] = useStore();
  var statuses = state.constants?.items?.PAYMENT_STATUS;
  var paymentTypes = state.constants?.items?.PAYMENT_TYPES;
  var documentTypes = state.constants?.items?.DOCUMENT_TYPES;

  return !statuses || statuses.length == 0 ? (
    <Loader />
  ) : (
    <>
      <div className="row">
        <div className="col-12">
          <a
            href={order.receiptStripeUrl}
            target="_BLANK"
            className="btn btn-link"
          >
            Voir la facture
            <i className="fa fa-external-link-alt ml-2" />
          </a>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Boutique</span>
            <div>{order.shop.name}</div>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Référence</span>
            <div>{order.reference}</div>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Date de la commande</span>
            <div>
              {DateTime.fromISO(order.createdAt).toFormat("dd / MM / yyyy")}
            </div>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Statut</span>
            <div
              className={
                "text-" + statuses.find((s) => s.id == order.status).color
              }
            >
              {statuses.find((s) => s.id == order.status).name}
            </div>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Mode de paiment</span>
            <div>
              <i
                className={
                  "fa mr-2 fa-" +
                  paymentTypes.find((s) => s.id == order.type).icon
                }
              />
              {paymentTypes.find((s) => s.id == order.type).name}
            </div>
          </label>
        </div>
        <div className="col-12 col-md-6">
          <label>
            <span>Montant</span>
            <div>{Math.round(parseFloat(order.amount * 100) / 100) / 100}€</div>
          </label>
        </div>
      </div>
      {order.orders.map((o, ok) => (
        <div className="row">
          <div className="col-12">
            <h4 className="mt-3 mb-0">
              <span className="text-default">SOCIETE : </span>
              <span className="mr-2">{o.companyName}</span>

              <span className="text-default" style={{ fontSize: 16 }}>
                ({o.siren})
              </span>
            </h4>
            <p className="text-black-50">
              <i>{o.legalForm}</i>
            </p>
          </div>
          <div className="col-12 col-md-6">
            <label>
              <span>Adresse de livraison</span>
              <div>{tools.getFullAddress(o.deliveryAddress)}</div>
            </label>
          </div>
          <div className="col-12 col-md-6">
            <label>
              <span>Adresse de facturation</span>
              <div>{tools.getFullAddress(o.billingAddress)}</div>
            </label>
          </div>
          <div className="col-12 mt-3" />
          {o.files.map((f, fk) => (
            <div className="col-12 col-md-4">
              <DocumentCard
                className="none"
                {...f}
                isCompany={true}
                key={"doc" + ok + "" + fk}
                noUpload
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default OrderDetail;
