import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import { STORE_KEY } from "../../../config";
import dataService from "../../../helpers/dataService";
import Loader from "../../common/Loader";

// window.onmessage = function (e) {
//   // inside the parent
//   let trackedEvents = [
//     "AddToCart",
//     "Purchase",
//     "DisplayRecap",
//     "DisplaySubscription",
//     "DisplayConnexion",
//     "DisplayPayment",
//   ];
//   if (window.dataLayer && trackedEvents.includes(e.data)) {
//     window.dataLayer.push({
//       event: e.data,
//     });
//   }
// };

const Command = (props) => {
  const [state, dispatch] = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [chests, setChests] = useState([]);
  const [errors, setErrors] = useState({});
  var containerParams = {
    element: "shop-form",
    apiKey: STORE_KEY,
    authToken: localStorage.jwtToken,
  };

  useEffect(() => {
    if (props.match.params.action == "renew") {
      setIsLoading(true);
      dataService.get(
        "companies/mines",
        (datas) => {
          containerParams.preloadCompanies = datas;
          // containerParams.preloadCompanies = datas
          //   .filter((d) => d.isCompany)
          //   .map((d) => ({
          //     activity: "",
          //     activityClass: "",
          //     address1: "",
          //     address2: "",
          //     ape: "",
          //     city: "",
          //     companyName: d.companyName,
          //     email: "",
          //     firstname: "",
          //     gender: "",
          //     lastname: "",
          //     legalForm: d.legalForm,
          //     postalCode: "",
          //     registrationDate: "",
          //     siren: d.siren,
          //     siret: "",
          //     turnover: 0,
          //     workforce: 0,
          //   }));
          loadContainer();
        },
        setErrors,
        () => setIsLoading(false)
      );
    } else {
      loadContainer();
    }
  }, []);

  const loadContainer = () => {
    window.ExternalComponent(containerParams).render("#shop-form");
  };

  return (
    <Page errors={errors} style={{ maxWidth: "95%" }}>
      {isLoading && <Loader />}
      <div id="shop-form" style={{ height: 1600 }}></div>
    </Page>
  );
};

export default Command;
