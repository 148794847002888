import React from "react";
import tools from "../../../helpers/tools";
import { useChestStore } from "../../pages/Chests/chestContext/ChestStore";

const InfosReduced = (props) => {
  const [chestState, dispatch] = useChestStore();

  const estab = chestState.establishments[0];
  return (
    <>
      <div>{tools.formatSiren(chestState.chest.siren)}</div>
      {estab && (
        <>
          {estab.address1 && <div>{estab.address1}</div>}
          {estab.postalCode && estab.city && (
            <div>
              {estab.postalCode}, {estab.city}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfosReduced;
