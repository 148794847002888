import { DateTime } from "luxon";
import React from "react";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../common/Loader";

const MustActivateAccount = () => {
  const [state, dispatch] = useStore();
  const aActions = authActions(state, dispatch);
  const { refreshAuth, isRefreshingAuth } = useAuth();

  const checkActivation = () => {
    refreshAuth();
  };

  return (
    <div
      style={{
        height: "100vh",
        marginTop: -64,
      }}
      className="d-center "
    >
      <div
        className="d-center flex-column mx-auto bg-blue p-4 shadow-small  text-left "
        style={{
          maxWidth: 350,
          fontSize: 16,
          borderRadius: 20,
        }}
      >
        <img src="/images/logo-h.png" style={{ height: 60 }} />
        <p className="d-block text-center w-100 mt-4">
          <strong>
            Vous devez activer votre compte pour pouvoir accéder à votre espace en ligne.{" "}
          </strong>
        </p>
        <p className="d-block text-center w-100">
          Cliquez sur le lien de l'email d'activation qui vous a été envoyé lors de votre
          souscription.
        </p>
        <p className="d-block w-100 text-center">Pensez à vérifier dans vos spams.</p>

        <button className="btn btn-primary text-white mb-2" onClick={checkActivation}>
          {isRefreshingAuth ? <Loader color="white" /> : "J'ai activé mon compte"}
        </button>
        <button className="btn btn-link" onClick={aActions.logoutUser}>
          Me déconnecter
        </button>
      </div>
    </div>
  );
};

export default MustActivateAccount;
