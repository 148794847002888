import React, { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../helpers/dataService";
import Page from "../../../common/layout/Page";
import Loader from "../../../common/Loader";
import SearchCompany from "./SearchCompany";
import { useHistory } from "react-router-dom";

const AddChest = () => {
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(false);
  const history = useHistory();

  const createChest = () => {
    setIsLoading(true);
    dataService.post(
      "chests",
      selectedCompany,
      (datas) => {
        toast.success("Votre coffre de société a bien été créé");
        history.push("/chests");
      },
      (errors) => {
        setErrors(errors);
        setSelectedCompany(false);
      },
      () => setIsLoading(false)
    );
  };

  return (
    <Page>
      {isLoading ? (
        <Loader text="Création de votre coffre" />
      ) : (
        <>
          <h2 className="mb-4">Ajouter un coffre</h2>
          <SearchCompany
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
          />
          {selectedCompany && (
            <div className="d-center flex-column mt-3">
              <button
                className="btn btn-primary  animated pulse infinite"
                onClick={createChest}
              >
                Créer un coffre pour cette société
              </button>
              <button
                className="btn btn-link mt-2"
                onClick={() => setSelectedCompany(false)}
              >
                Annuler
              </button>
            </div>
          )}
        </>
      )}
    </Page>
  );
};

export default AddChest;
