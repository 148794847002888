import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Page from "../../common/layout/Page";
import Loader from "../../common/Loader";
import SubscriptionList from "./SubscriptionList/SubscriptionList";
import SubscriptionsFilters from "./SubscriptionList/SubscriptionsFilters/SubscriptionsFilters";
import useSubscriptions from "./useSubscriptions";

const Subscriptions = () => {
  const { filteredSubscriptions, isInit, isLoadingSubscriptions, actions } = useSubscriptions(true);
  const { id } = useParams();

  useEffect(() => {
    actions.setSubscriptions([]);
  }, []);

  const renderList = () => {
    const noResult = isInit && filteredSubscriptions.length == 0;
    if (isLoadingSubscriptions) {
      return <Loader />;
    } else {
      if (noResult) {
        return (
          <div className="alert alert-default">
            Aucun abonnement n'est encore disponible.{" "}
            <button className="btn btn-link px-0" onClick={actions.getSubscriptions}>
              Cliquez ici pour recharger la liste.
            </button>
          </div>
        );
      } else {
        return <SubscriptionList openedSub={id} />;
      }
    }
  };

  return (
    <Page
      back="/"
      breadcrumb={[
        { name: "Accueil", path: "/" },
        { name: "Abonnements", path: "/subscriptions" },
      ]}
    >
      <SubscriptionsFilters />
      {renderList()}
    </Page>
  );
};

export default Subscriptions;
