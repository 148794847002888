import React from "react";
import Page from "../../common/layout/Page";
import CtaBox from "../Dashboard/CtaBox/CtaBox";

const Account = () => {
  return (
    <Page
      title=""
      breadcrumb={[
        { name: "Accueil", path: "/" },
        { name: "Mon compte", path: "/account" },
      ]}
    >
      <div className="row mt-0 mt-md-5 ">
        {/* <CtaBox
          text={<>Vérifier mon compte</>}
          subText="Activer mon KYC pour bénéficier d'avantages"
          link="/"
          linkText="Accéder à ma sécurité"
          icon="icon-chest-light.svg"
        /> */}
        <CtaBox
          text={<>Mes abonnements</>}
          subText="Modifier et vérifier vos abonnements."
          link="/subscriptions"
          linkText="Voir mes abonnements"
          icon="icon-document.svg"
        />
        <CtaBox
          text={<>Compte</>}
          subText="Modifier email, mot de passe et profil."
          link="/profile"
          linkText="Voir mon compte"
          icon="icon-account.svg"
        />
        <CtaBox
          text={<>Aide</>}
          subText="Demander de l'aide à notre équipe LegaDrive."
          link="/contact"
          linkText="Demander de l'aide"
          icon="icon-help.svg"
          bgColor="rgba(16,74,150,0.1)"
        />
      </div>
    </Page>
  );
};

export default Account;
