import React from "react";
import useCancelSubscription from "../useCancelSubscription";
import FinishStep from "./FinishStep";
import ReasonStep from "./ReasonStep";
import SubscriptionStep from "./SubscriptionStep/SubscriptionStep";

const Steps = () => {
  const steps = [<SubscriptionStep />, <ReasonStep />, <FinishStep />];

  const { step } = useCancelSubscription();

  return (
    <div className="p-3 rounded border" style={{ background: "#FCFCFC" }}>
      {steps[step]}
    </div>
  );
};

export default Steps;
