import { DateTime } from "luxon";
import React, { useEffect } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link } from "react-router-dom";
import { colors } from "../../../helpers/colors";
import Colors from "../../../theme/variables.scss";
import Loader from "../../common/Loader";
import useSharedFiles from "./useSharedFiles";

var recaptchaShare;
const SharedFile = (props) => {
  const {
    link,
    verifyCallback,
    download,
    isLoading,
    setIsLoading,
    isDownloading,
    errors,
  } = useSharedFiles({
    token: props.match.params.token,
  });

  useEffect(() => {
    setIsLoading(true);
  }, []);

  return (
    <>
      <div className="d-center flex-column">
        {errors.other && (
          <div className="alert alert-danger d-center flex-column">
            {errors.other}
            <Link to="/" className="btn btn-link mt-3">
              <i className="fa fa-arrow-left mr-2" />
              Revenir à l'accueil
            </Link>
          </div>
        )}
        {isLoading ? (
          <>
            <div className="mt-4">
              <Loader />
            </div>
            <GoogleReCaptcha onVerify={verifyCallback} />
          </>
        ) : !link ? null : (
          <>
            <img className="m-5" height="50" src="/images/logo-h.png" />
            <h2
              className="text-center mb-5 mx-4"
              style={{ fontSize: 40, lineHeight: "44px", fontWeight: "800" }}
            >
              Bonjour, <br />
              <span className="text-capitalize">{link.createdBy}</span> vous a
              partagé un document
            </h2>
            <div
              className="d-center flex-column bg-white shadow px-5 py-4"
              style={{ borderRadius: 20 }}
            >
              <img height="40" src="/images/folder.png" />
              <i
                className="fa fa-arrow-down"
                style={{ position: "relative", top: -8, fontSize: 20 }}
              />
              <strong style={{ color: Colors.secondary, fontSize: 17 }}>
                Téléchargez vos documents
              </strong>
              <div
                className="d-center flex-column"
                style={{ color: colors.secondary }}
              >
                <div className="mt-1" style={{ fontSize: 12 }}>
                  Les fichiers sont disponibles jusqu'au{" "}
                  {DateTime.fromISO(link.expiredAt).toFormat("dd/MM/yyyy")}
                </div>
              </div>

              <ul className="list-group mt-3">
                {link.files.map((file, fk) => (
                  <li
                    className="list-group-item cursor-pointer"
                    onClick={() => download(file, fk)}
                  >
                    <button className="btn btn-secondary d-flex flex-row  w-100 align-items-center justify-content-start">
                      <div className="font-weight-bold d-flex flex-fill mr-2 text-left">
                        {file.labels.map((l) => l.label).join(", ")}
                      </div>
                      {isDownloading == fk ? (
                        <Loader color="white" />
                      ) : (
                        <i className="fa fa-download" />
                      )}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="d-center flex-wrap text-secondary mt-5 bg-white"
              style={{ borderRadius: 20 }}
            >
              <div className="text-center text-md-left px-3 mb-3 mb-md-0">
                Vous aussiez stocker et partagez vos documents{" "}
                <span className="text-primary">gratuitement</span> avec
                LegaDrive. Prêt ? Partez !
              </div>
              <Link className="btn btn-secondary d-center btn-sm" to="/">
                Créer mon compte
              </Link>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SharedFile;
