import React from "react";

const ControlValidIcon = (props) => {
  return (
    <i
      style={{ position: "absolute", left: -21, top: -16 }}
      className={
        "valid-icon fa fa-" +
        (props.icon ? props.icon : props.hasError ? "times" : "check") +
        " " +
        (props.hasError
          ? "text-danger delay-1s animated fadeIn fast"
          : "text-success  animated bounceIn ")
      }
    />
  );
};

export default ControlValidIcon;
