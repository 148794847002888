import Axios from "axios";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";

function DropId({ type = "ID", onDocumentUploaded }) {
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const onDrop = useCallback((acceptedFiles) => {
    updateFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function updateFile(files) {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (files.length > 0 && allowedTypes.indexOf(files[0].type) != -1) {
      let data = new FormData();
      data.append("file", files[0]);
      data.append("fileType", type);

      uploadFile(data);
    } else {
      alert(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  }
  function uploadFile(data) {
    setIsUploading(true);
    Axios.post(API_URL + "certifications/me", data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        onDocumentUploaded(res.data);
        toast.success("Votre document a bien été envoyé");
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez réessayer");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div
        className="d-center text-center mt-3"
        style={{
          border: "2px dashed grey",
          height: 200,
          width: "100%",
          fontSize: 24,
          background: "#f1f1f1",
          cursor: "pointer",
        }}
      >
        {isDragActive ? (
          <p className="m-0 p-3">Déposez le fichier ici</p>
        ) : !isUploading ? (
          <p className="m-0 p-3 animated pulse infinite slower">
            Cliquez ici pour choisir une photo, ou glissez-déposez un fichier
            dans ce cadre
          </p>
        ) : (
          <div className="d-center p-3">
            <Loader text={"Envoi en cours"} />
          </div>
        )}
      </div>
      {Object.keys(errors).length ? (
        <div className="alert alert-danger">{errors.other}</div>
      ) : null}
    </div>
  );
}

export default DropId;
