import React from "react";
import useStore from "../../../context/useStore";

const CustomModal = () => {
  const [state, dispatch] = useStore();

  const modalProps = state.modal;

  return (
    <div
      className="position-fixed w-100 h-100 align-items-center justify-content-center"
      style={{
        display: modalProps.isOpen ? "flex" : "none",
        top: 0,
        left: 0,
        background: "rgb(0 0 0 / 54%)",
        zIndex: "9999",
        overflowY: "auto",
      }}
    >
      <div
        className="bg-white position-relative custom-modal-content"
        style={{
          width: "min(90%,664px)",
          borderRadius: 5,
          ...modalProps.style,
        }}
      >
        {modalProps.content}

        <div>{modalProps.actions}</div>
      </div>
    </div>
  );
};

export default CustomModal;
