import React, { useState } from "react";
import LabelContainer from "../../../../../common/Controls/LabelContainer";
import usePaymentMethods from "./usePaymentMethods";

const PaymentMethodButton = ({ ...props }) => {
  const { name, value, datas, dataIndex, change } = props;

  const valueChange = (e, val, isExclusive) => {
    var filteredValue = val;
    let changeEvent = {
      target: {
        name: name,
        value: filteredValue == props.value ? "" : filteredValue,
        k: props.k,
      },
    };
    if (change) {
      change(changeEvent);
    }
  };

  return (
    <div className="d-center flex-row flex-wrap align-items-center justify-content-start">
      {datas.map((data) => {
        let isActive = data[dataIndex] == value;
        return (
          <button
            key={`pm${data.info}`}
            onClick={(e) => valueChange(e, data[dataIndex])}
            type="button"
            className={`px-3 mb-2 py-2 btn d-flex align-items-center justify-content-between flex-row shadow-sm flex-fill border position-relative w-100 ${
              isActive ? "btn-primary text-white" : "btn-white bg-white"
            }`}
          >
            <div>
              <i
                className={`pf mr-3 pf-${data.brand} p-2 rounded shadow-sm ${
                  isActive ? "bg-white text-primary" : "bg-secondary text-white"
                }`}
              />
            </div>
            <div>
              <div>{data.info}</div>
              <div>
                <small style={{ fontSize: 12 }}>{data.expireAt}</small>
              </div>
            </div>
            {isActive && (
              <i
                className={`check-icon fa fa-check ml-2 animated faster 
          position-absolute bg-light text-primary border border-primary shadow-sm 
                  `}
                style={{
                  top: -7,
                  right: -7,
                  fontSize: 8,
                  padding: 5,
                  borderRadius: "100%",
                  zIndex: "90",
                }}
              />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default PaymentMethodButton;
