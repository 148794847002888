import React from "react";
import { Link } from "react-router-dom";
import { colors } from "../../../helpers/colors";

const Breadcrumb = ({ links = [] }) => {
  return (
    <div className="mb-3">
      {links.map((link, lk) => (
        <Link
          key={`bclink${link.name}`}
          to={link.path}
          style={{
            color: lk == links.length - 1 ? colors.secondary : colors.light,
            fontSize: 20,
            fontWeight: "800",
          }}
        >
          {link.name}
          {lk < links.length - 1 && "/ "}
        </Link>
      ))}
    </div>
  );
};

export default Breadcrumb;
