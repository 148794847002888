import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useStore from "../../../../context/useStore";
import NotificationBtn from "./NotificationBtn";
import $ from "jquery";
import Notification from "./Notification";

const Notifications = ({ setNotifVisible }) => {
  const [state, dispatch] = useStore();

  const notifications = state.notifications.items;

  return (
    <>
      <div className="btn-group notif-dropdown">
        <NotificationBtn setDropdownVisible={setNotifVisible} notifications={notifications} />
        <div className="notif-dropdown-menu shadow-lg p-0" style={{ borderRadius: 10 }}>
          {notifications.map((n, ns) => (
            <Notification
              notification={n}
              key={`notif-${n.id}`}
              setDropdownVisible={setNotifVisible}
            />
          ))}
          {!notifications.length && <div className="p-2 text-black-50">Aucune notification</div>}
        </div>
      </div>
    </>
  );
};

export default Notifications;
