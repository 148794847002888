import axios from "axios";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../config";
import dataService from "../../../../../helpers/dataService";

const useFile = ({ files, onDeleted = () => {} }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const downloadFile = (fileId) => {
    setIsDownloading(fileId);
    axios({
      url: `${API_URL}files/${fileId}/download`,
      method: "GET",
      responseType: "blob",
    })
      .then((res) => {
        var filename = res.headers["content-disposition"].split("=")[1];
        fileDownload(res.data, filename);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          toast.error(err.response.data.other);
        }
      })
      .then(() => {
        setIsDownloading(false);
      });
  };

  const deleteFile = (fileId, fileType) => {
    if (!window.confirm("Confirmez-vous la suppression de ce document ?")) {
      return false;
    }
    setIsDeleting(fileId);
    dataService.remove(
      `files/${fileId}`,
      {},
      (data) => {
        onDeleted(fileId, fileType);
      },
      (err) => {},
      () => setIsDeleting(false)
    );
  };

  return { downloadFile, isDownloading, deleteFile, isDeleting };
};

export default useFile;
