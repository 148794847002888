import React, { useEffect, useState } from "react";
import CreateBillingAddress from "./CreateBillingAddress";
import ModifyBillingAddress from "./ModifyBillingAddress";
import SelectBillingAddress from "./SelectBillingAddress";
import DeleteBillingAddress from "./DeleteBillingAddress";
import ActionButton from "./ActionButton";
import useBillingAddresses from "./useBillingAddresses";

const BillingAddress = ({ disabled }) => {
  const { subState, getBillingAddresses } = useBillingAddresses();

  useEffect(() => {
    getBillingAddresses();
  }, []);

  const addressData = subState.billingAddress;

  const [mode, setMode] = useState(false);

  const onFinishSelect = () => {
    setMode(false);
  };
  const onFinishModify = () => {
    setMode(false);
  };
  const onFinishCreate = () => {
    setMode(false);
  };
  const onFinishDelete = () => {
    setMode(false);
  };

  return (
    <>
      <strong
        className="d-flex justify-content-between align-items-center mb-2"
        style={{ fontSize: 22 }}
      >
        Adresse de facturation{" "}
        {subState.billingAddresses && (
          <small className="bg-default rounded px-2 text-secondary d-inline-center">
            {subState.billingAddresses.length}
          </small>
        )}
      </strong>

      {!mode && (
        <div>
          <ul style={{ fontSize: 16 }}>
            <li>
              <div>
                <strong className="text-secondary">{addressData.name}</strong>
              </div>
              <div>
                {addressData.address1} {addressData.address2}
              </div>
              <div>
                {addressData.postalCode}, {addressData.city}
              </div>
            </li>
          </ul>
          {!disabled && (
            <div className=" d-flex flex-column">
              <ActionButton onClick={() => setMode("modify")}>
                <i className="fa fa-edit mr-2" />
                Modifier cette adresse
              </ActionButton>
              {subState.billingAddresses && subState.billingAddresses.length > 1 && (
                <>
                  <ActionButton onClick={() => setMode("change")}>
                    <i className="fa fa-list mr-2" />
                    Choisir une autre adresse
                  </ActionButton>
                  <ActionButton onClick={() => setMode("delete")}>
                    <i className="fa fa-times mr-2" />
                    Supprimer une adresse
                  </ActionButton>
                </>
              )}
              <ActionButton onClick={() => setMode("create")}>
                <i className="fa fa-plus mr-2" />
                Ajouter une nouvelle adresse
              </ActionButton>
            </div>
          )}
        </div>
      )}

      {mode == "change" ? (
        <SelectBillingAddress onFinish={onFinishSelect} />
      ) : mode == "modify" ? (
        <ModifyBillingAddress onFinish={onFinishModify} />
      ) : mode == "create" ? (
        <CreateBillingAddress onFinish={onFinishCreate} />
      ) : mode == "delete" ? (
        <DeleteBillingAddress onFinish={onFinishDelete} />
      ) : null}
    </>
  );
};

export default BillingAddress;
