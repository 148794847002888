import React, { useState, useEffect, useRef } from "react";
import Control from "../Controls/Control";
import useStore from "../../../context/useStore";
import Loader from "../Loader";
import { modalActions } from "../../../context/actions/modalActions";
import Axios from "axios";
import { API_URL, APP_URL } from "../../../config";
import { toast } from "react-toastify";
import tools from "../../../helpers/tools";
import { DateTime } from "luxon";

const ShareDocument = ({ doc }) => {
  const [state, dispatch] = useStore();
  const [deadline, setDeadline] = useState("");
  const modal_actions = modalActions(state, dispatch);
  const [isLoading, setIsLoading] = useState(false);
  const [shareLink, setShareLink] = useState(false);

  const inpRef = useRef();

  useEffect(() => {
    getLink();
  }, []);

  const getLink = () => {
    setIsLoading(true);
    Axios.get(API_URL + "links/active/files/" + doc.id)
      .then((res) => {
        if (res.data.token) {
          setShareLink(res.data);
        }
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez fermer puis réessayer");
      })
      .then(() => setIsLoading(false));
  };

  const share = () => {
    setIsLoading(true);
    Axios.post(API_URL + "links", {
      file: doc.id,
      deadline,
    })
      .then((res) => {
        setShareLink(res.data);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez fermer puis réessayer");
      })
      .then(() => setIsLoading(false));
  };

  const copyText = () => {
    var inpRef = document.querySelector("input[name='shareLink']");
    inpRef.select();
    inpRef.setSelectionRange(0, 99999);
    document.execCommand("copy");
    toast.success("Le lien a été copié dans votre presse-papier");
  };

  const removeShare = () => {
    setIsLoading(true);
    Axios.delete(API_URL + "links/" + shareLink.id)
      .then((res) => {
        toast.success("Le partage de ce document a été annulé");
        setShareLink(false);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez fermer puis réessayer");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return state.constants.items.LINK_DEADLINES && !isLoading ? (
    <div className="d-center flex-column p-5">
      <h2 className="mb-4">Partager le document</h2>
      <p>
        Vous pouvez partager ce document avec d'autres personnes en leur
        communiquant son lien de partage.
      </p>
      {!shareLink.token ? (
        <Control
          className="w-100"
          label="Combien de temps souhaitez-vous partager ce document ?"
          type="select"
          name="deadline"
          value={deadline}
          datas={state.constants.items.LINK_DEADLINES}
          change={(e) => setDeadline(e.target.value)}
        />
      ) : (
        <>
          <div className="alert alert-default w-100">
            <p className="mb-2">
              Ce document a été téléchargé{" "}
              <strong>{shareLink.downloadHistorics.length}</strong> fois.
            </p>
            <p className="mb-0">
              Il expirera le{" "}
              <strong>
                {DateTime.fromISO(shareLink.expiredAt).toFormat(
                  "dd/MM/yyyy à HH:mm"
                )}
              </strong>
            </p>
          </div>
          <Control
            className="w-100"
            label="Lien de partage du document"
            type="text"
            name="shareLink"
            value={APP_URL + "shared/" + shareLink.token}
          />
        </>
      )}
      <div className="text-right w-100">
        {deadline !== "" && !shareLink.token && (
          <button
            className="btn btn-primary animated fadeInRight faster"
            onClick={share}
          >
            <i className="fa fa-share-alt mr-2" />
            Obtenir le lien de partage
          </button>
        )}
        {shareLink.token && (
          <>
            <button
              className="btn btn-secondary animated fadeInRight faster"
              onClick={removeShare}
            >
              <i className="fa fa-times mr-2" />
              Ne plus partager
            </button>
            <button
              className="btn btn-primary animated fadeInRight faster"
              onClick={copyText}
            >
              <i className="fa fa-copy mr-2" />
              Copier le lien
            </button>
          </>
        )}
        <button
          className="btn btn-default"
          onClick={() => {
            modal_actions.updateModal({ isOpen: false, content: null });
          }}
        >
          fermer
        </button>
      </div>
    </div>
  ) : (
    <div className="my-5">
      <Loader />
    </div>
  );
};

export default ShareDocument;
