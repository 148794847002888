import React, { useEffect, useState } from "react";

const PresentationContent = (props) => {
  return (
    <div
      className={
        "m-content text-center bg-white  d-flex align-items-center justify-content-start flex-column position-relative"
      }
      style={{
        width: props.nbSlides * 100 + "%",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        marginTop: 150,
      }}
    >
      <img
        className="position-absolute"
        src={"/images/" + props.image}
        style={{
          width: "min(60vw,301px)",
          top: "0",
          height: 270,
          maxHeight: 270,
          transform: "translateY(-55%)",
        }}
      />
      <div
        className=""
        style={{
          width: "90%",
          marginTop: 130,
        }}
      >
        <h2 style={{ fontSize: "min(23px,1.6rem)" }} className="mb-3">
          {props.title}
        </h2>
        <p style={{ color: "#B3B3B3" }}>{props.text}</p>
      </div>
    </div>
  );
};

export default PresentationContent;
