import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Axios from "axios";
import { API_URL } from "../../config";
import Loader from "./Loader";
import { toast } from "react-toastify";

const DropFile = ({ type, chestId, setDocuments }) => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({});
  const [animClassname, setAnimClassname] = useState("");
  const onDrop = useCallback((acceptedFiles) => {
    setErrors({});
    updateFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function updateFile(files) {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (files.length > 0 && allowedTypes.indexOf(files[0].type) != -1) {
      let data = new FormData();
      data.append("file", files[0]);
      data.append("fileType", type);

      uploadFile(data);
    } else {
      alert(
        "Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf."
      );
    }
  }
  function uploadFile(data) {
    setIsUploading(true);
    Axios.post(API_URL + "chests/" + chestId + "/files", data, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (percentCompleted >= progress + 25) {
          setProgress(percentCompleted);
        }
        // do whatever you like with the percentage complete
        // maybe dispatch an action that will update a progress bar or something
      },
    })
      .then((res) => {
        setDocuments(res.data);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez réessayer");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  }

  useEffect(() => {
    var anim =
      progress == 100
        ? "loading-100"
        : progress > 75
        ? "loading-75"
        : progress > 50
        ? "loading-50"
        : progress > 25
        ? "loading-25"
        : "";

    if (anim != animClassname) {
      setTimeout(() => {
        setAnimClassname(anim);
      }, 200);
    }
  }, [progress]);

  useEffect(() => {
    if (!isUploading) {
      setProgress(0);
      setTimeout(() => {
        setAnimClassname("no");
      }, 800);
    }
  }, [isUploading]);

  return (
    <div
      {...getRootProps()}
      style={{
        fontSize: 14,
        border: "1px dashed",
        borderRadius: 15,
      }}
      className={`dropzone d-center h-100 p-3 text-center w-100 position-relative overflow-hidden ${animClassname}`}
      id={`doc-${chestId}${type}`}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <p className="mb-0 animated pulse infinite">Déposez le fichier ici</p>
      ) : isUploading ? (
        <Loader text={progress + "%"} />
      ) : (
        <p className="mb-0">
          Glissez-déposez un fichier ici ou cliquez pour parcourir vos dossiers.
        </p>
      )}
    </div>
  );
};

export default DropFile;
