import React from "react";
import { Link } from "react-router-dom";
import dataService from "../../../helpers/dataService";

const AllDone = ({ datas }) => {
  return (
    <div className="w-100 d-center flex-column">
      <img
        className="img-fluid animated zoomIn fast"
        src="/images/verify-success.svg"
      />
      <p className="mt-3 text-secondary" style={{ fontSize: 18 }}>
        {datas.status == "PROBLEM"
          ? "Merci, la vérification de votre identité est en cours, vous serez averti de son résultat prochainement."
          : "Votre compte Pro est activé ! Les coffres des entreprises suivantes ont été passés en statut vérifié : "}
      </p>
      {datas.status !== "PROBLEM" && (
        <>
          <ul className="list-group">
            {datas.companies.map((comp, compk) => (
              <li
                key={"comp" + compk}
                className="list-group-item d-flex justify-content-between align-items-center animated fadeInUp faster"
                style={{ animationDelay: compk * 50 + "ms" }}
              >
                <div>
                  <strong>{comp.companyName}</strong> - {comp.siren}
                </div>
                <div className="ml-4">
                  <img src="/images/icon-verified.svg" />
                </div>
              </li>
            ))}
          </ul>
          <Link to="/chests" className="btn btn-tertiary d-center mt-5">
            Accéder à mes coffres
            <i className="fa fa-arrow-right ml-2" />
          </Link>
        </>
      )}
    </div>
  );
};

export default AllDone;
