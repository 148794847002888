const initialState = {
  isDataLoaded: false,
  extendedInfos: {},
  isLoading: false,
  paymentMethods: [],
  billingAddresses: [],
};

export const initSubscription = (payload) => ({
  type: "INIT_CHEST",
  payload,
});

export const setIsLoading = (payload) => ({
  type: "IS_LOADING",
  payload,
});
export const updateStatus = (payload) => ({
  type: "UPDATE_STATUS",
  payload,
});
export const initPaymentMethods = (payload) => ({
  type: "INIT_PAYMENT_METHODS",
  payload,
});
export const populateBillingAddresses = (payload) => ({
  type: "POPULATE_BILLING_ADDRESSES",
  payload,
});
export const populateBillingAddressesAndSetSelected = (payload) => ({
  type: "POPULATE_BILLING_ADDRESSES_AND_SET_SELECTED",
  payload,
});
export const changeSelectedAddress = (payload) => ({
  type: "CHANGE_SELECTED_ADDRESS",
  payload,
});
export const populateInvoices = (payload) => ({
  type: "POPULATE_INVOICES",
  payload,
});

export const changeSelectedPaymentMethod = (payload) => ({
  type: "CHANGE_SELECTED_PAYMENT_METHOD",
  payload,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case "INIT_CHEST":
      return action.payload;
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "INIT_PAYMENT_METHODS":
      return { ...state, paymentMethods: action.payload };
    case "POPULATE_BILLING_ADDRESSES":
      return { ...state, billingAddresses: action.payload };
    case "CHANGE_SELECTED_ADDRESS":
      return { ...state, billingAddress: action.payload };
    case "CHANGE_SELECTED_PAYMENT_METHOD":
      return { ...state, providerData: action.payload };
    case "POPULATE_BILLING_ADDRESSES_AND_SET_SELECTED":
      return {
        ...state,
        billingAddresses: action.payload.billingAddresses,
        billingAddress: action.payload.billingAddress,
      };
    case "POPULATE_INVOICES":
      return {
        ...state,
        invoices: action.payload,
      };
    case "UPDATE_STATUS":
      return {
        ...state,
        status: action.payload,
      };

    default:
      return state;
  }
}
