import React from "react";
import { Link } from "react-router-dom";
import Loader from "../../../../common/Loader";
import useCancelSubscription from "../useCancelSubscription";

const FinishStep = () => {
  const { isLoading } = useCancelSubscription();

  return (
    <div className="d-center flex-column">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <img src="/images/icon-success.svg" className="animated zoomIn faster" />
          <div
            className="text-success text-center font-weight-bold mt-4"
            style={{
              maxWidth: 240,
            }}
          >
            VOTRE DEMANDE DE RÉSILIATION A BIEN ÉTÉ PRISE EN COMPTE
            <div className="mt-5">
              <Link to="/account" className="btn btn-primary">
                Revenir à mon compte
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FinishStep;
