import React, { useEffect } from "react";

import CompanyInfos from "../../common/CompanyInfos/CompanyInfos";

import DocumentList from "./ChestDetail/DocumentList/DocumentList";
import useChestBox from "./useChestBox";

const ChestBox = ({ dk }) => {
  const { isOpen, setIsOpen, chestState } = useChestBox();
  const amIOpen = isOpen == chestState.chest?.id;

  useEffect(() => {
    if (amIOpen) {
      document.querySelector(`#chest-${chestState.chest.id}`)?.scrollIntoView();
    }
  }, [isOpen]);

  return (
    <div
      className={`${
        amIOpen ? "col-12 border rounded shadow-sm pr-0" : "col-12 col-md-4"
      } mb-4 animated fadeIn faster position-relative`}
      id={`chest-${chestState.chest.id}`}
      style={{
        animationDelay: `${dk * 50}ms`,
        background: amIOpen ? "linear-gradient(90deg, rgb(14 43 103) 15px, #e6effe 15px)" : "none",
        overflow: "hidden",
      }}
    >
      <CompanyInfos />
      {amIOpen && (
        <>
          <DocumentList />
          <button
            className="border-0 text-disabled"
            style={{
              position: "absolute",
              top: 10,
              right: 10,
              background: "rgba(0,0,0,0.001)",
              fontSize: 16,
            }}
            onClick={() => setIsOpen(null)}
          >
            <i className="fa fa-minus-circle mr-1" />
            Réduire
          </button>
        </>
      )}
    </div>
  );
};

export default ChestBox;
