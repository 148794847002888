import React from "react";
import Colors from "../../../theme/variables.scss";

const ModalNavigation = (props) => {
  var nbSteps = props.slides.length;

  return (
    <div
      className="w-100 modal-nav d-flex align-items-center justify-content-between px-4 bg-white flex-wrap"
      style={{
        bottom: 10,
        left: 0,
      }}
    >
      <button
        className="btn btn text-secondary font-weight-bold d-flex  flex-fill align-items-center"
        onClick={props.closeModal}
        style={{ fontSize: 12 }}
      >
        Passer l'introduction
      </button>
      <div className="d-flex  flex-fill align-items-center justify-content-center">
        {props.slides.map((s, sk) => (
          <div
            className="mx-1"
            style={{
              width: 10,
              height: 10,
              background:
                sk == props.currentSlide ? Colors.secondary : Colors.light,
              borderRadius: "100%",
            }}
          ></div>
        ))}
      </div>
      <div className="d-flex flex-fill justify-content-between align-items-center">
        <button
          disabled={props.currentSlide <= 0}
          className="btn modal-nav-btn text-secondary font-weight-bold"
          style={{ fontSize: 16, opacity: props.currentSlide <= 0 ? "0" : "1" }}
          onClick={() => props.changeSlide(-1)}
        >
          <i className="fa fa-chevron-left mr-2" />
          <span>Précédent</span>
        </button>

        <button
          className="btn  modal-nav-btn text-secondary font-weight-bold"
          style={{ fontSize: 16 }}
          onClick={() => props.changeSlide(1)}
        >
          <span>
            {props.currentSlide < props.slides.length - 1
              ? "Suivant"
              : "Terminer"}
          </span>
          <i className="fa fa-chevron-right ml-2" />
        </button>
      </div>
    </div>
  );
};

export default ModalNavigation;
