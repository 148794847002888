import React from "react";
import LabelContainer from "../LabelContainer";

const SelectInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "Choisissez...",
  datas = [],
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false,
  dataLabel2 = false,
  dataLabel3 = false,
  ...props
}) => {
  const valueChange = (e) => {
    var filteredValue = e.target.value;
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <LabelContainer {...props} value={value}>
      <select
        style={inputStyle ? inputStyle : {}}
        className={"form-control " + inputClassName}
        disabled={disabled ? "disabled" : false}
        value={value ? value : ""}
        name={props.name}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        onChange={valueChange}
        placeholder={placeholder}
      >
        <option value="">{placeholder}</option>
        {datas.map((data, k) => (
          <option
            key={props.name + "-" + (data.id ? data.id : k)}
            value={data[dataIndex]}
          >
            {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}{" "}
            {dataLabel2 ? " - " + data[dataLabel2] : ""}
            {dataLabel3 ? " - " + data[dataLabel3] : ""}
          </option>
        ))}
      </select>
    </LabelContainer>
  );
};

export default SelectInput;
