import React, { useEffect, useState } from "react";
import Page from "../../common/layout/Page";
import DocumentList from "./DocumentList";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import { API_URL } from "../../../config";
import Loader from "../../common/Loader";
import ChestBox from "./ChestBox";
import AskDocument from "./ChestDetail/AskDocuments/AskDocument";
import { useAtom } from "jotai";
import { chestsAtom, errorsAtom, isLoadingAtom, openedChestId } from "./atoms/atoms";
import { ChestStoreProvider } from "./chestContext/ChestStore";
import chestReducer from "./chestContext/chestReducer";

const Chests = () => {
  const [chests, setChests] = useAtom(chestsAtom);
  const [errors, setErrors] = useAtom(errorsAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [openedChest, setOpenedChest] = useAtom(openedChestId);
  const [isInit, setIsInit] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    if (id) {
      setOpenedChest(id);
    }
  }, [id]);

  const getDocuments = () => {
    setIsLoading(true);
    Axios.get(API_URL + "chests/mines")
      .then((res) => {
        setChests(res.data);
        setIsInit(true);
      })
      .catch((err) => {
        if (err.response?.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const updateChests = (doc) => {
    var docs = JSON.parse(JSON.stringify(chests));
    docs.forEach((d) => {
      if (d.id == doc.chest) {
        d.files.forEach((f, fk) => {
          if (f.type == doc.type) {
            d.files[fk] = doc;
          }
        });
      }
    });
    setChests(docs);
  };

  return (
    <>
      <Page
        errors={errors}
        breadcrumb={[
          { name: "Accueil", path: "/" },
          { name: "Coffres", path: "/chests" },
        ]}
        actionBar={
          <div className="d-flex justify-content-end">
            <Link to="/command" className="btn btn-primary mb-3">
              <i className="fa fa-plus mr-2" />
              Ajouter un coffre
            </Link>
          </div>
        }
      >
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row">
            {isInit && chests.length == 0 && (
              <div className="col-12">
                <div className="alert alert-default">
                  Aucun coffre n'est encore disponible.{" "}
                  <button className="btn btn-link px-0" onClick={getDocuments}>
                    Cliquez ici pour rafraichir la liste.
                  </button>
                </div>
              </div>
            )}
            {chests.map((d, dk) => (
              <ChestStoreProvider
                initialState={{
                  ...d,
                }}
                reducer={chestReducer}
                key={"chest" + dk}
              >
                <ChestBox params={d} setChests={updateChests} dk={dk} reload={getDocuments} />
              </ChestStoreProvider>
            ))}
          </div>
        )}
        {/* <AskDocument /> */}
      </Page>
    </>
  );
};

export default Chests;
