import React from "react";

const EmptyButton = ({ name, change, targetInput, value, right = 0 }) => {
  const emptyVal = () => {
    targetInput.current.focus();
    change({
      target: {
        name: name,
        value: "",
      },
    });
  };

  var hasValue = value && value !== "";

  return (
    <div
      className={`btn text-light animated ${hasValue ? "fadeIn" : "fadeOut"}`}
      style={{
        position: "absolute",
        right: right,
        top: 0,
        height: 30,
        width: 30,
        padding: "0 5px",
        minHeight: 0,
        borderRadius: "50%",
        zIndex: "9",
        display: "grid",
        placeItems: "center",
      }}
      onClick={emptyVal}
    >
      <i className="fa fa-times" />
    </div>
  );
};

export default EmptyButton;
