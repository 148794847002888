import { DateTime } from "luxon";
import React, { useState } from "react";
import dataService from "../../../../../helpers/dataService";
import Loader from "../../../../common/Loader";
import { updateStatus } from "../../subscriptionContext/subscriptionReducer";
import { useSubscriptionStore } from "../../subscriptionContext/SubscriptionStore";

const UncancelSubscriptionButton = ({ cancelDate }) => {
  const [subState, dispatch] = useSubscriptionStore();
  const [isLoading, setIsLoading] = useState(false);

  const uncancelSubscription = () => {
    setIsLoading(true);
    dataService.patch(
      `subscriptions/${subState.id}/uncancel`,
      {},
      (data) => {
        dispatch(updateStatus(data.status));
      },
      (err) => {
        console.log(err);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const canUncancel = subState.echeance > DateTime.local().toISO() && subState.status == "CANCELED";
  if (!canUncancel) return null;

  return (
    <button
      className="btn btn-default mt-2 d-center"
      onClick={uncancelSubscription}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader className="mr-2" color="black" size={20} />
      ) : (
        <i className="fa fa-refresh mr-2" />
      )}
      REACTIVER L'ABONNEMENT
    </button>
  );
};

export default UncancelSubscriptionButton;
