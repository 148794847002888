import axios from "axios";
import fileDownload from "js-file-download";
import { useState } from "react";
import { toast } from "react-toastify";
import { API_URL } from "../../../config";
import dataService from "../../../helpers/dataService";

const useSharedFiles = ({ token }) => {
  const [link, setLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(-1);
  const [errors, setErrors] = useState({});

  const getLink = (recaptchaToken) => {
    setIsLoading(true);
    dataService.post(
      `links/token/${token}`,
      {
        captchaToken: recaptchaToken,
      },
      setLink,
      setErrors,
      setIsLoading(false)
    );
  };

  const download = (file, fk) => {
    if (file.fileId) {
      if (isDownloading != -1) return false;
      setIsDownloading(fk);
      axios({
        url: `${API_URL}links/token/${token}/files/${file.fileId}`,
        method: "GET",
        responseType: "blob",
      })
        .then((res) => {
          var filename = res.headers["content-disposition"].split("=")[1];
          fileDownload(res.data, filename);
        })
        .catch((err) => {
          toast.error("Impossible de télécharger ce document, ce lien de partage a expiré.");
        })
        .then(() => {
          setIsDownloading(-1);
        });
    }
    if (file.externalUrl) {
      window.open(file.externalUrl);
    }
  };

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!

    getLink(recaptchaToken);
  };

  return {
    getLink,
    link,
    verifyCallback,
    download,
    isLoading,
    setIsLoading,
    isDownloading,
    errors,
  };
};

export default useSharedFiles;
