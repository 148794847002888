import React from "react";
import { useSubscriptionStore } from "../../subscriptionContext/SubscriptionStore";

const ChestList = () => {
  const [subState, dispatch] = useSubscriptionStore();

  return (
    <>
      <strong
        className="d-flex justify-content-between align-items-center mb-2"
        style={{ fontSize: 22 }}
      >
        Coffres{" "}
        <small className="bg-default rounded px-2 text-secondary d-inline-center">
          {subState.chests.length}
        </small>
      </strong>
      <ul>
        {subState.chests.map((chest, ck) => (
          <li key={`chest${chest.id}${ck}`}>
            <div>
              <strong className="text-secondary">{chest.chest.companyName}</strong>{" "}
              <small>- {chest.chest.siren}</small>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default ChestList;
