import React, { useState, useEffect } from "react";
import Page from "../../common/layout/Page";
import useStore from "../../../context/useStore";
import CtaBox from "./CtaBox/CtaBox";
import { modalActions } from "../../../context/actions/modalActions";
import ModalPresentation from "./ModalPresentation";

const Dashboard = () => {
  const [state, dispatch] = useStore();
  const modal_actions = modalActions(state, dispatch);
  const [titleColor, setTitleColor] = useState("white");

  useEffect(() => {
    if (localStorage.presentationViewed !== "1") {
      modal_actions.updateModal({
        isOpen: true,
        content: <ModalPresentation />,
      });
    }
  }, []);

  return (
    <Page>
      <div className={""}>
        <div className="row pb-4">
          <div className="col-12">
            <h2 style={{ fontSize: 45, lineHeight: "40px" }}>
              Bonjour {state.auth.user.firstname}
            </h2>
          </div>
        </div>
        <div className="row mt-0 mt-md-5 ">
          <div className="col-12">
            <h2>Que souhaitez-vous faire aujourd'hui ?</h2>
          </div>
          <CtaBox
            text={
              <>
                Besoin d'ajouter
                <br />
                une entreprise ?
              </>
            }
            link="/command"
            linkText="Ajouter un coffre"
            icon="icon-plus.svg"
            bgColor="rgba(16,74,150,0.1)"
          />
          <CtaBox
            text={
              <>
                Tous mes
                <br />
                coffres
              </>
            }
            link="/chests"
            linkText="Voir mes coffres"
            icon="icon-coffre.svg"
          />
          <CtaBox
            text={
              <>
                Gérer mes
                <br />
                abonnements
              </>
            }
            link="/subscriptions"
            linkText="Voir mes abonnements"
            icon="icon-document.svg"
          />
          <CtaBox
            text={<>Mon compte</>}
            subText="Modifier email, mot de passe et profil."
            link="/profile"
            linkText="Accéder à mon compte"
            icon="icon-account.svg"
          />
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
