import React from "react";

const FileShareItem = ({ file, isSelected, addFile, removeFile }) => {
  return (
    <div className="border-bottom d-flex flex-row align-items-center justify-content-between mb-1 py-1">
      <div>{file.labels.map((label) => label.label).join(", ")}</div>
      {isSelected ? (
        <div className="d-flex  align-items-center">
          <i className="fa fa-check text-success mr-2 animated fadeInLeft faster" />
          <button
            className="btn btn-sm btn-default"
            style={{ minWidth: 85 }}
            onClick={() => removeFile(file)}
          >
            <i className="fa fa-times mr-2" />
            Retirer
          </button>
        </div>
      ) : (
        <button
          className="btn btn-sm btn-success"
          style={{ minWidth: 85 }}
          onClick={() => addFile(file)}
        >
          <i className="fa fa-plus mr-2" />
          Ajouter
        </button>
      )}
    </div>
  );
};

export default FileShareItem;
