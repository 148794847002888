import React from "react";
import { modalActions } from "../../../../../context/actions/modalActions";
import useStore from "../../../../../context/useStore";

const RenewKbisModal = ({ onClick }) => {
  const [state, dispatch] = useStore();
  const mActions = modalActions(state, dispatch);

  const onBtnClick = () => {
    onClick();
    closeModal();
  };

  const closeModal = () => {
    mActions.updateModal({
      isOpen: false,
      content: null,
      style: {},
    });
  };

  return (
    <div
      className="p-3 d-center flex-column position-relative"
      style={{
        backgroundImage: "url(/images/icon-attention.svg)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-36px 19px",
      }}
    >
      <div
        className="text-left text-secondary"
        style={{
          paddingLeft: 124,
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >
        <strong>Information</strong>
        <p className="text-left mt-2">Votre Kbis n'est renouvelable qu'une seule fois par mois.</p>
        <button className="btn btn-primary btn-sm" onClick={onBtnClick}>
          Renouveler mon Kbis
        </button>
      </div>
      <button
        onClick={closeModal}
        className="btn btn-default rounded-circle text-dark d-center"
        style={{
          position: "absolute",
          top: 7,
          right: 7,
          height: 30,
          width: 30,
        }}
      >
        <i className="fa fa-times" />
      </button>
    </div>
  );
};

export default RenewKbisModal;
