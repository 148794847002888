import React, { useState, useEffect, useReducer, useRef } from "react";
import ControlValidIcon from "./ControlValidIcon";
import Validation from "./Validation";

const LabelContainer = ({
  labelClassname = "",
  k = -1,
  name,
  label = false,
  required = false,
  suffix = false,
  error = {},
  ...props
}) => {
  const labelRef = useRef(null);
  var compareValue = props.value === 0 ? props.value : props.value ? props.value : [];
  return (
    <label
      ref={labelRef}
      className={`position-relative ${labelClassname} ${!props.isValid ? " has-error " : " "} 
      ${error[name] ? " has-api-error " : " "}
        `}
      htmlFor={k != -1 ? name + "-" + k : name}
      style={{
        ...props.labelStyle,
      }}
    >
      {label && (
        <span className="label-text">
          {label}
          {required && !Validation.required(compareValue) && (
            <span className="text-danger ml-1">*</span>
          )}
        </span>
      )}
      <div className="input-group">
        {props.children}
        {label && <ControlValidIcon hasError={!props.isValid} icon={props.validIcon} />}
        {suffix && <div className="input-group-append">{suffix}</div>}
      </div>
      {props.after && <p>{props.after}</p>}
      {error[name] && (
        <small
          className={
            "form-text input-error-msg text-danger " + (error[name] ? "animated flash" : "")
          }
          style={{
            top: 6,
            right: 8,
            fontWeight: "bold",
            fontSize: 12,
          }}
        >
          {error[name]}
        </small>
      )}
    </label>
  );
};

export default LabelContainer;
