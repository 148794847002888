import React from "react";
import useStore from "../../../../../context/useStore";
import Control from "../../../../common/Controls/Control";
import SaveBtn from "../../../../common/SaveBtn";
import useCancelSubscription from "../useCancelSubscription";

const ReasonStep = () => {
  const { cancelReason, actions, isLoading } = useCancelSubscription();
  const [state] = useStore();
  const { SUBSCRIPTION_CANCEL_REASONS } = state.constants.items;

  return (
    <div className="p-3 mx-auto" style={{ maxWidth: 690 }}>
      <div
        className="mb-3 text-secondary"
        style={{
          fontSize: 22,
        }}
      >
        <strong>Pourquoi voulez-vous résilier votre abonnement ?</strong>
      </div>
      <Control
        type="btnList"
        value={cancelReason}
        k={1}
        change={(e) => actions.handleChangeCancelReason(e.target.value)}
        datas={SUBSCRIPTION_CANCEL_REASONS}
        dataIndex="id"
        dataLabel="name"
        btnColor="btn-outline-secondary border "
        labelStyle={{
          border: "0",
          padding: 0,
        }}
      />

      {cancelReason && (
        <SaveBtn
          type="primary"
          text={"Soumettre mon avis"}
          save={actions.cancelSubscription}
          isSaving={isLoading}
        />
      )}
    </div>
  );
};

export default ReasonStep;
