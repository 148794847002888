import { useAtom } from "jotai";
import { useState } from "react";
import dataService from "../../../../helpers/dataService";
import {
  cancelReasonAtom,
  isFinishedAtom,
  isLoadingAtom,
  stepAtom,
  subscriptionAtom,
} from "./atoms/CancelSubscriptionAtoms";

const useCancelSubscription = () => {
  const maxStep = 2;
  const [step, setStep] = useAtom(stepAtom);
  const [subscription, setSubscription] = useAtom(subscriptionAtom);
  const [cancelReason, setCancelReason] = useAtom(cancelReasonAtom);
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [isFinished, setIsFinished] = useAtom(isFinishedAtom);

  const nextStep = () => {
    if (step < maxStep) {
      setStep(step + 1);
    }
  };
  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };
  const goToStep = (step) => {
    setStep(step);
  };

  const initSubscription = (subscriptionData) => {
    setSubscription(subscriptionData);
  };

  const handleChangeCancelReason = (newCancelReason) => {
    setCancelReason(newCancelReason);
  };

  const cancelSubscription = () => {
    if (isLoading) return false;

    setIsLoading(true);

    dataService.patch(
      `subscriptions/${subscription.id}/cancel`,
      { cancelReason },
      (data) => {
        nextStep();
        setIsFinished(true);
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return {
    step,
    maxStep,
    subscription,
    cancelReason,
    isLoading,
    isFinished,
    actions: {
      cancelSubscription,
      initSubscription,
      nextStep,
      prevStep,
      goToStep,
      handleChangeCancelReason,
    },
  };
};

export default useCancelSubscription;
